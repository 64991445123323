<script setup lang="ts">
const { open, documents } = defineProps(["open", "documents"]);
const emit = defineEmits(["clickDocument"]);

const handleClickDocument = (documentId: string) => {
  emit("clickDocument", documentId);
};
</script>

<template>
  <transition name="slide-fade">
    <ul v-show="open" class="documents">
      <li class="document" v-for="document in documents" :key="document.id">
        <div
          class="document__content"
          @click="() => handleClickDocument(document.id)"
        >
          <div class="document__name">
            <v-icon>mdi-file-document-outline</v-icon>
            <span>{{ document.name }}</span>
          </div>
          <div v-if="document.stripe_price_id === null" class="document__badge">
            FREE
          </div>
        </div>
      </li>
    </ul>
  </transition>
</template>

<style scoped lang="scss">
.documents {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 300px;
  margin-left: 20px;
}

.document {
  height: auto;

  .document__content {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    border: 1px solid var(--grey);
    align-items: center;
    transition: background-color 0.2s ease-in-out;

    .document__name {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .document__badge {
      background-color: var(--blue-bonnet);
      color: white;
      padding: 5px 10px;
      font-size: 0.75rem;
      font-weight: 500;
      margin-left: 15px;
      height: 28px;
    }

    &:hover {
      cursor: pointer;
      background-color: var(--light-grey);
    }
  }
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.slide-fade-enter-from, .slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

@media (max-width: 768px) {
  .documents {
    width: 90%;
  }
}
</style>
