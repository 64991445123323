<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import axios from "axios";
import _debounce from "lodash/debounce";
import { useToast } from "vue-toastification";
import { documentStatuses } from "@/utils/statuses";
import { useIsMobile } from "@/utils/checkMobile";
import DocumentCard from "@/components/documents/list/DocumentCard.vue";
import CreateDirectory from "@/components/documents/list/CreateDirectory.vue";
import DirectoryCard from "@/components/documents//list/DirectoryCard.vue";
import DocumentPreview from "@/components/documents/list/DocumentPreview.vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import Page from "@/components/Page.vue";
import { useStore } from "vuex";
import BackButton from "@/components/BackButton.vue";
import setUserData from "@/utils/setUserData";

const { t } = useI18n();
const isMobile = useIsMobile();
const store = useStore();
const isTemporaryUser = computed(() => store.state.auth.user.isTemporary);
const isSubscribed = computed(() => store.state.auth.user.hasValidSubscription);
const directory = computed(() => selectedDirectory.value?.name);
const pageTitle = computed(() =>
  directory.value
    ? `${t("documents.my_documents")} / ${directory.value}`
    : t("documents.my_documents")
);
const emit = defineEmits([
  "setCurrentDirectory",
  "removeDirectory",
  "createDirectory",
]);
const toast = useToast();
const $t = useI18n().t;
const router = useRouter();

const documents = ref([]);
const directories = ref([]);
const dialog = ref(false);
const currentPage = ref(1);
const isLastPage = ref(false);
const isLoading = ref(false);
const selectedDocumentId = ref(null);
const documentToMove = ref(null);

const shouldRedirectToLatestDocument = computed(
  () => router.currentRoute.value.name === "payment-success"
);

const redirectToLatestDocument = () => {
  const latestDocument = documents.value[0];

  router.push({
    name: "new-document-creator",
    params: {
      uuid: latestDocument.id,
    },
  });
};

const showDialog = (documentId = null) => {
  dialog.value = true;
  documentToMove.value = documentId;
};

const documentStatusList = documentStatuses.map((status) => ({
  title: $t("documents.statuses." + status.name),
  value: status.value,
}));

const documentNameFilter = ref(null);
const onDocumentNameFilter = _debounce(() => getDocuments(true), 500);

const documentStatusFilter = ref(null);
const directoryFilter = ref("null");

const removeDirectory = async () => {
  try {
    await axios.delete(`/api/directories/${directoryFilter.value}`);
    toast.success($t("notifications.directory_removed"));
    setCurrentDirectory("null");
    await getDirectories();
  } catch (error) {
    toast.error($t("errors.documents.directory_removal_error"));
  }
};

const getDocuments = async (force = false) => {
  isLoading.value = true;

  if (force === true) {
    currentPage.value = 1;
  }

  try {
    const { data } = await axios.get("/api/documents", {
      params: {
        page: currentPage.value,
        per_page: 24,
        "filter[name]": documentNameFilter.value,
        "filter[status]": documentStatusFilter.value,
        "filter[directory_id]": directoryFilter.value,
        sort: "-created_at",
      },
    });

    if (!documents.value.length || force === true) {
      documents.value = data.data;
    } else {
      documents.value.push(...data.data);
    }
    currentPage.value++;
    isLastPage.value = !data.next_page_url;
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

const getDirectories = async () => {
  if (isTemporaryUser.value || !isSubscribed.value) {
    return;
  }

  try {
    const { data } = await axios.get("/api/directories");
    directories.value = data.data;
  } catch (error) {
    console.error(error);
  }
};

const moveToDirectory = async (directoryId, documentId) => {
  try {
    await axios.put(`/api/documents/${documentId}/directory`, {
      directory_id: directoryId,
    });
    useToast().success($t("notifications.document_moved"));
    refreshPage();
  } catch (error) {
    useToast().error($t("errors.documents.move_error"));
  }
};

const handleOnCreateDirectory = async (directoryId) => {
  await getDirectories();
  if (documentToMove.value) {
    await moveToDirectory(directoryId, documentToMove.value);

    documentToMove.value = null;
  }
};

const refreshPage = async () => {
  await Promise.all([getDocuments(true), getDirectories()]);
};

const setCurrentDirectory = (id) => {
  isLoading.value = true;
  documents.value = [];
  directoryFilter.value = id ?? "null";
};

const selectedDirectory = computed(() =>
  directories.value.find((directory) => directory.id === directoryFilter.value)
);

const debouncedGetDocuments = _debounce(() => getDocuments(true), 300);

const handleOpenDocument = (clickedDocument) =>
  (selectedDocumentId.value = clickedDocument);

watch([documentStatusFilter, directoryFilter], () => debouncedGetDocuments());

onMounted(async () => {
  await refreshPage();

  if (shouldRedirectToLatestDocument.value) {
    await setUserData();
    redirectToLatestDocument();
  }

  //open document from url
  const uuidInRoute = router.currentRoute.value.params.documentId;
  if (uuidInRoute) {
    selectedDocumentId.value = uuidInRoute;
  }
});
</script>

<template>
  <Page :title="pageTitle">
    <template #back-button>
      <BackButton
        @click.stop="setCurrentDirectory('null')"
        :mobile="isMobile"
        :to="directory ? '' : '/new-document'"
        margin-top="0"
        margin-bottom="0"
        class="mr-4"
      />
    </template>
    <template #buttons>
      <v-btn
        @click="showDialog(null)"
        class="v-btn-custom mr-3"
        v-if="!directory && !isTemporaryUser && isSubscribed"
      >
        <template v-slot:prepend>
          <img src="/src/assets/icons/plus.svg" alt="Add directory" />
        </template>
        {{ $t("documents.create_directory") }}
      </v-btn>
      <v-btn
        class="v-btn-custom mr-3"
        v-if="!!directory"
        @click="removeDirectory"
      >
        {{ $t("documents.remove_directory") }}
      </v-btn>
      <RouterLink to="/new-document">
        <v-btn class="v-btn-custom">
          <template v-slot:prepend>
            <img src="/src/assets/icons/plus.svg" alt="Add document" />
          </template>
          {{ $t("documents.create_document") }}
        </v-btn>
      </RouterLink>
    </template>

    <template #default>
      <div v-if="!shouldRedirectToLatestDocument">
        <DocumentPreview
          v-if="selectedDocumentId"
          :documentId="selectedDocumentId"
          @close="selectedDocumentId = null"
        />

        <div class="document-filters">
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="documentNameFilter"
                @input="onDocumentNameFilter"
                variant="outlined"
                density="compact"
                :placeholder="$t('documents.name')"
                hide-details
              >
                <template v-slot:append-inner>
                  <img src="/src/assets/icons/search.svg" alt="Search" />
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                v-model="documentStatusFilter"
                :items="documentStatusList"
                variant="outlined"
                density="compact"
                :label="$t('documents.status')"
                clearable
                hide-details
              />
            </v-col>
          </v-row>
        </div>
        <div v-if="directoryFilter === 'null'" class="directories-container">
          <v-row>
            <template v-for="directory in directories" :key="directory.id">
              <DirectoryCard
                :directory="directory"
                @setCurrentDirectory="setCurrentDirectory"
                @removeDirectory="removeDirectory"
              />
            </template>
          </v-row>

          <div class="mt-15 mb-12">
            <div class="spacer"></div>
          </div>
        </div>
        <div class="documents-container">
          <template v-if="documents.length">
            <v-infinite-scroll
              :items="documents"
              mode="manual"
              class="overflow-x-hidden"
              width="100%"
            >
              <v-row>
                <template v-for="document in documents" :key="document.id">
                  <v-col cols="12" xl="3" md="4" sm="12">
                    <DocumentCard
                      :document-statuses="documentStatuses"
                      :document="document"
                      :directories="directories"
                      :show-delete-button="true"
                      @refresh-page="refreshPage"
                      @open-document="handleOpenDocument"
                      @createDirectory="showDialog"
                    />
                  </v-col>
                </template>
              </v-row>
              <template v-slot:load-more>
                <v-btn
                  variant="tonal"
                  v-if="!isLastPage"
                  :disabled="isLoading"
                  @click="getDocuments"
                  class="mt-5 form-button"
                  >{{ $t("common.load_more") }}
                </v-btn>
              </template>
            </v-infinite-scroll>
          </template>
          <template v-else-if="isLoading">
            <v-progress-linear class="mt-3" indeterminate color="primary" />
          </template>
          <template v-else>
            <div class="mt-3">{{ $t("documents.no_documents") }}</div>
          </template>
        </div>
      </div>
      <CreateDirectory v-model="dialog" @onCreate="handleOnCreateDirectory" />
    </template>
  </Page>
</template>

<style scoped lang="scss">
.form-button {
  font-size: 1.125rem;
  color: white !important;
  background-color: var(--cambridge-blue);
  text-transform: none;
  border-radius: 0;
  min-width: 145px;
  height: 45px;
}

.document-filters {
  margin-top: 20px;
}

.directories-container {
  margin-top: 15px;
}

.documents-container {
  display: flex;
  gap: 50px;
}

button {
  margin-bottom: 5px;

  @media (max-width: 768px) {
    margin-bottom: 15px !important;
    width: 100% !important;
  }
}
</style>
