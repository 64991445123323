<script setup>

import Page from "@/components/Page.vue";
import MobileChat from "@/components/mobile/MobileChat.vue";
import axios from "axios";

const initialMessages = ref([
  {
    id: crypto.randomUUID(),
    source: "bot",
    message: "Hello! How can I help you today?",
  },
]);

const getChatHistory = async () => {
  const response = await axios.get(
    `api/chat-messages`
  );
  initialMessages.value =
    response.data.data.length === 0
      ? [
        {
          id: crypto.randomUUID(),
          source: "bot",
          message: "Hello! How can I help you today?",
        },
      ]
      : response.data.data;
};

onMounted(() => {
  getChatHistory();
});
</script>

<template>
  <Page title="AI Legal Assistant" :with-back-button="true">
    <MobileChat :initial-messages="initialMessages"/>
  </Page>
</template>

<style scoped lang="scss">

</style>
