<script setup>
import { useRouter } from "vue-router";

const router = useRouter();

const props = defineProps(["isDialogOpen", "document"]);
const emit = defineEmits(["closeDialog"]);
</script>
<template>
  <v-dialog v-model="props.isDialogOpen" width="400">
    <v-card :title="$t('common.requiresAuth')">
      <v-card-text>
        {{ $t("common.requiresAuthMessage") }}
      </v-card-text>

      <v-card-actions>
        <v-btn
          :text="$t('common.cancel')"
          variant="tonal"
          @click="emit('closeDialog')"
        ></v-btn>

        <v-spacer></v-spacer>

        <v-btn
          @click="router.push('/login')"
          :text="$t('auth.login')"
          type="submit"
          color="primary"
          variant="flat"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
