<script setup>
import {useRoute} from "vue-router";
import {useToast} from "vue-toastification";
import router from "@/router";
import store from "@/store/store";
import {useI18n} from "vue-i18n";
import axios from "axios";

const route = useRoute();
const $t = useI18n().t;
const provider = route.params.provider;

const handleLoginByProvider = async () => {
  try {
    await store.commit("auth/logout");
    const response = await axios.post(`api/login/${provider}/callback`, route.query);
    await store.commit("auth/login", response.data.token);
    useToast().success($t('notifications.login_success'));
    await router.push("/new-document");
  } catch (error) {
    useToast().error($t('notifications.login_error'));
  }
};

onMounted(() => {
  handleLoginByProvider()
})
</script>

<template>
  <div class="container">
    <img class="logo" src="/src/assets/images/logo.png" alt="Legaltorch logo"/>
    <div class="form-container">
      <v-progress-circular color="#0026ef" indeterminate :size="60"></v-progress-circular>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  background-color: white;
  display: flex;
  gap: 50px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>
