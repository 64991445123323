export function useHelpUser(
  currentDocument,
  store,
  token,
  chatId,
  state,
  socket,
  isJson,
  parseToXml
) {
  const handleNoAction = (data) => {
    if (!currentDocument) {
      return;
    }

    console.log("Asking for help");
    const messageId = crypto.randomUUID();
    const documentContent =
      store.state.editor.content.content ||
      (store.state.editor.content
        ? store.state.editor.content
        : currentDocument?.content);

    const metadata = {
      document: isJson(documentContent)
        ? parseToXml(documentContent, currentDocument?.meta)
        : documentContent,
      template_uuid: currentDocument
        ? currentDocument?.template_awakast_id
        : null,
      command: {
        command: "help_user",
        step_id: data.type === "wizard" ? data.id : null,
        variable_id: data.type === "variable" ? data.id : null,
      },
    };
    const message = {
      token: token.value,
      message_id: messageId,
      document_id: currentDocument ? currentDocument.id : null,
      chat_id: chatId.value,
      input: "",
      state: { state: state.value },
      metadata: metadata,
      template_title: currentDocument ? currentDocument?.meta?.title : null,
      template_uuid: currentDocument ? currentDocument?.template_id : null,
    };
    console.log("Message:", message);
    socket.value.send(JSON.stringify(message));
    console.log("AskedForHelp");
  };

  return { handleNoAction };
}
