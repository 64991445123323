<script setup>
const emits = defineEmits(["send-message"]);
const {chatDisabled} = defineProps({
  chatDisabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const charsLimit = ref(100);
const message = ref("");
const errorMessage = ref("");

const sendMessage = () => {
  const isLimitExcided = message.value.length > charsLimit.value;

  // if (isLimitExcided) {
  //   errorMessage.value = "Message is too long";
  //   return;
  // }

  emits("send-message", message.value);
  message.value = "";
};

watch(message, () => {
  errorMessage.value = "";
});
</script>

<template>
  <div class="message-container">
    <v-textarea
      v-model.trim="message"
      type="text"
      :placeholder="$t('chat.message_placeholder')"
      variant="outlined"
      density="compact"
      auto-grow
      rows="1"
      max-rows="3"
      :disabled="chatDisabled"
      @keyup.enter="sendMessage"
      @keydown.enter.prevent
      :error-messages="errorMessage"
      hide-details
    >
      <template v-slot:append-inner>
        <img
          class="message-button"
          @click="sendMessage"
          src="/src/assets/icons/send.svg"
          alt=""
          draggable="false"
      /></template>
    </v-textarea>
  </div>
</template>

<style scoped lang="scss">
.message-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;

  :deep(.v-field__append-inner) {
    align-items: center;
  }

  .message-button {
    width: 30px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
  }
}
</style>
