<script setup>
import { useRouter } from "vue-router";

const { route } = defineProps(["route"]);

const router = useRouter();
const handleAssistantRedirect = async () => {
  await router.push(route);
};
</script>

<template>
  <div @click="handleAssistantRedirect" class="assistant-footer">
    <img
      src="/src/assets/icons/virtual-assistant-header.svg"
      alt="Virtual assistant icon"
    />
    <span class="assistant"> AI Legal Assistant </span>
  </div>
</template>

<style scoped lang="scss">
.assistant-footer {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 10px;
  background-color: var(--light-grey);
  padding: 25px;

  .assistant {
    color: var(--blue-bonnet);
    font-size: 1.5rem;
    font-weight: 600;
  }
}
</style>
