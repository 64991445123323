import useCookies from "@/utils/cookies"

const cookies = useCookies();

export const userLogoutHandler = () => {
  const path = window.location.pathname;
  const authPages = [
    "/login",
    "/register",
    "/forgot-password",
    "/reset-password",
  ];
  const isAuthPage = authPages.includes(path);

  if (isAuthPage) {
    return;
  }

  cookies.delete("apiToken");
  localStorage.removeItem("apiToken");
  localStorage.removeItem("isLogin");
  window.location.replace("/login");
};
