<script setup>
import LoadingMessage from "@/components/chat/messages/LoadingMessage.vue";
import Message from "@/components/chat/messages/Message.vue";

const { messages } = defineProps(["messages"]);
const emit = defineEmits(["load"]);
</script>

<template>
  <v-infinite-scroll side="start" @load="(e) => emit('load', e)">
    <template v-slot:loading>
      <LoadingMessage />
    </template>
    <div
      v-for="(message, index) in messages"
      :key="message.id"
      class="messages-container"
    >
      <Message :message="message.message" :source="message.source" />
    </div>
  </v-infinite-scroll>
</template>

<style scoped lang="scss">
.messages-container {
  display: flex;
  flex-direction: column;
}
</style>
