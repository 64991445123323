<script setup>
import axios from "axios";
import { onMounted, ref } from 'vue';
import DocumentCard from "@/components/documents/list/DocumentCard.vue";
import BackButton from "@/components/BackButton.vue";
import DocusignEmbedUI from "@/views/app/docusign/DocusignEmbedUI.vue";

const documents = ref([]);
const selectedDocument = ref(null);
const documentForSignature = ref(null);
const dragRef = ref(null);
const dropRef = ref(null);
const isDocumentsContainerOpen = ref(true);
const isDocusignConnected = ref(false);

const getDocuments = async () => {
  const response = await axios.get("api/documents", {
    params: {
      "filter[status]": "ready_for_review_or_signature",
      "sort": "-created_at"
    },
  });
  documents.value = response.data.data;
};

const selectDocument = (id) => {
  selectedDocument.value = id;
};

const uploadDocument = () => {
  documentForSignature.value = selectedDocument.value;
};

const onDragEnter = () => {
  document.getElementsByClassName('docusign-embed')[0].style.display = 'none';
  dropRef.value.style.display = 'flex';
};

const onDragLeave = () => {
  document.getElementsByClassName('docusign-embed')[0].style.display = 'block';
  dropRef.value.style.display = 'none';
};

onMounted(() => {
  getDocuments();
});
</script>

<template>
  <div class="page">
    <div class="page-header">
      <h3>
        {{ $t('documents.signatures.sign_documents') }}
      </h3>
    </div>
    <BackButton/>
    <div class="page-container">
      <div
        class="drop-zone-container"
        :class="isDocumentsContainerOpen ? 'drop-zone-container-padding' : ''"
      >
        <div
          id="drop-zone"
          class="drop-zone-overlay"
          ref="dropRef"
          @drop.prevent="uploadDocument"
          @dragover.prevent
        >
          Drop your document here
        </div>
        <DocusignEmbedUI
          @docusignConnected="isDocusignConnected = true"
          :documentId="documentForSignature"
          class="docusign-embed"
        />
      </div>

      <div v-if="documents.length" class="documents-to-sign-container" :class="!isDocumentsContainerOpen ? 'cursor-pointer' : ''">
        <template v-if="isDocumentsContainerOpen">
          <div class="d-flex justify-space-between">
            <h4>{{ $t('documents.signatures.documents_to_sign') }}</h4>
            <div class="minimize-button">
              <v-btn size="sm" icon @click="isDocumentsContainerOpen = !isDocumentsContainerOpen" variant="plain">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="documents-to-sign">
            <v-col class="document-card" cols="12" xl="3" md="3" sm="12" v-for="document in documents" :key="document.id"
                   :class="{ 'draggable': isDocusignConnected }"
                   ref="dragRef"
                   :draggable="isDocusignConnected"
                   @dragstart="() => {
                     onDragEnter();
                     selectDocument(document.id)
                   }"
                   @dragend="onDragLeave"
            >
              <DocumentCard :show-sign-button="false" class="draggable" :document="document" :directories="[]" :document-statuses="[]"/>
            </v-col>
          </div>
        </template>
        <template v-else>
          <div @click="isDocumentsContainerOpen = !isDocumentsContainerOpen" class="d-flex justify-space-between">
            <div></div>
            <h4>{{ $t('documents.signatures.show_ready_documents') }}</h4>
            <div class="minimize-button">
              <v-btn size="sm" icon variant="plain">
                <v-icon>mdi-arrow-expand</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;

  .page-header {
    display: flex;
    width: 100%;
    font-weight: bold;
    font-size: 1.5rem;

    h3 {
      border-image: linear-gradient(to left, var(--cambridge-blue), var(--booger-buster)) 30;
      border-width: 2px;
      border-style: solid;
      border-top: 0px;
      border-left: 0px;
      border-right: 0px;
      width: 100%;
      padding-bottom: 15px;
    }
  }

  .page-container {
    margin-top: 20px;

    .documents-to-sign-container {
      width: calc(100vw - var(--v-layout-left) - 40px);
      padding: 20px;
      display: block;
      position: fixed;
      bottom: 0;
      background-color: white;
      z-index: 999;
      border-top: 1px solid var(--light-grey);

      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 100px;
        position: relative;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .documents-to-sign {
      width: 100%;
      overflow-x: scroll;
      white-space: nowrap;
      display: flex;
      gap: 25px;

      .document-card {
        padding: 25px 0 25px 0;
        cursor: grab;
      }

      .draggable {
        cursor: grab;
      }
    }

    .drop-zone-container {
      position: relative;
      padding-bottom: 50px;
    }

    .drop-zone-container-padding {
      padding-bottom: 300px;

      @media (max-width: 768px) {
        padding-bottom: 0;
      }
    }

    .drop-zone-overlay {
      position: absolute;
      display: none;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      border: 2px dashed var(--blue-bonnet);
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 2em;
      color: var(--egyptian-blue);
      min-height: 38vh;
    }
  }
}
</style>
