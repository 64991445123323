<script setup>

import InputCustom from "@/components/auth/InputCustom.vue";
import {validateEmail} from "@/utils/validation";
import axios from "axios";
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import {Checkbox, useRecaptchaProvider} from "vue-recaptcha";
import ContactSupportDialog from "@/components/dialogs/ContactSupportDialog.vue";
import ButtonLink from "@/components/partials/ButtonLink.vue";

useRecaptchaProvider();

const email = ref("");
const errors = reactive({
  email: "",
  google_recaptcha_response: "",
});
const errorMessage = ref("");
const isLoading = ref(false);
const $t = useI18n().t;
const google_recaptcha_response = ref("");

const isValid = () => {
  errors.google_recaptcha_response = google_recaptcha_response.value === '' ? "Please check the reCAPTCHA checkbox" : "";
  errors.email = validateEmail(email.value);

  return Object.values(errors).every((error) => error === "");
};

const handleSubmit = async () => {
  if (isValid()) {
    isLoading.value = true;

    try {
      const response = await axios.post("api/forgot-password", {
        email: email.value,
        google_recaptcha_response: google_recaptcha_response.value,
      });

      if (response.status === 200) {
        useToast().success($t("notifications.forgot_password_success"));
        email.value = "";
        errorMessage.value = "";
      } else {
        useToast().error($t("notifications.default_error"));
      }
    } catch (error) {
      errorMessage.value = error.response.data.message;
      useToast().error(errorMessage.value);
    } finally {
      isLoading.value = false;
    }
  }
};

const isContactSupportDialogOpen = ref(false)

const showContactSupportDialog = () => {
  isContactSupportDialogOpen.value = true
}

</script>

<template>
  <div class="container">
    <img class="logo" src="/src/assets/images/logo.svg" alt="Legal torch logo"/>
    <div class="form-container">
      <form class="form" @submit.prevent="handleSubmit">
        <h3 class="subtitle">{{ $t('auth.forgot_password') }}</h3>

        <InputCustom
          type="email"
          v-model="email"
          autocomplete="email"
          :error="errors.email || errorMessage"
          :label=" $t('common.email')"
        />

        <Checkbox v-model="google_recaptcha_response"/>

        <template v-if="errors.google_recaptcha_response">
          <div class="recaptcha-error">{{ $t('auth.recaptcha') }}</div>
        </template>

        <v-btn
          type="submit"
          block
          variant="flat"
          :loading="isLoading"
          class="form-button"
        >
          {{ $t('auth.reset_password') }}
        </v-btn>

        <p class="bottom-link">
          <router-link class="link" to="/login">
            <span class="underline"> {{ $t("auth.remember_the_password") }} </span>
          </router-link>

          <ButtonLink variant="tonal" class="link" @click="showContactSupportDialog">
            <span class="underline"> {{$t("auth.need_help")}} </span>
          </ButtonLink>
        </p>
      </form>
    </div>
    <ContactSupportDialog
      :is-dialog-open="isContactSupportDialogOpen"
      @close-dialog="isContactSupportDialogOpen = false"
    />
  </div>
</template>
<style scoped lang="scss">
.container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100%;

  .form-container {
    width: 100%;
    display: flex;
    justify-content: center;
    background: transparent url('@/assets/backgrounds/auth.png') 40% 90% no-repeat padding-box;
    background-size: 35%;
    min-height: 610px;
  }

  .logo {
    width: 256px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.form {
  width: 430px;
  height: 100%;
  border: 1px solid var(--grey);
  color: white;
  margin: 0 5%;
  background-color: white;
  padding: 50px 50px 250px;

  @media (max-width: 1000px) {
    margin: 0 auto;
    min-width: 340px;
  }

  .subtitle {
    text-align: center;
    margin-bottom: 50px;
    font-size: 1.5rem;
    color: var(--eerie-black)
  }

  .recaptcha-error {
    color: rgb(var(--v-theme-error));
    font-size: 0.75rem;
  }

  .form-button {
    margin-top: 30px;
    background-color: var(--booger-buster);
    color: var(--eerie-black) !important;
    font-size: 1.125rem;
    font-weight: bold;
    height: 41px;
  }
}
.bottom-link {
  text-align: left;
  color: var(--eerie-black);
  font-size: 0.75rem;
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  a {
    color: var(--eerie-black);

    &:hover {
      color: rgb(var(--v-theme-primary));
      text-decoration: underline;
    }
  }
}

.link {
  text-align: left;
  color: var(--eerie-black);
  font-weight: normal;
}

.link:hover {
  color: rgb(var(--v-theme-secondary)) !important;
}

@media (max-width: 768px) {
  .form-container {
    width: 80% !important;
    background: none !important;
  }
  .container {
    background: transparent url("@/assets/backgrounds/auth.png") 0 90%
    no-repeat padding-box;
  }

  .form {
    padding: 30px;
  }
}
</style>
