<script setup>
import { defineProps } from "vue";
import { useI18n } from "vue-i18n";

import { useIsMobile } from '@/utils/checkMobile';
import SubscriptionInfoTableVaiot
  from "@/components/users/profile/subscription_form/subscription_info/SubscriptionInfoTableVaiot.vue";
import CurrentPlanInfo from "@/components/users/profile/subscription_form/CurrentPlanInfo.vue";

const isMobile = useIsMobile();
const { subscriptionData } = defineProps(["subscriptionData"]);

const { t } = useI18n();
</script>
<template>
  <div class="box">
    <h4 v-if="!isMobile" class="header">{{ t("billing.subscription") }}</h4>

    <SubscriptionInfoTableVaiot
      v-if="subscriptionData"
      :subscription="subscriptionData"
    />

    <CurrentPlanInfo :is-premium="subscriptionData" />
  </div>
</template>
<style scoped lang="scss">
.box {
  max-width: 580px;
}
.header {
  margin-bottom: calc(30px + 24px);
}
</style>
