<script setup>
import { useStore } from "vuex";
import MarkdownIt from "markdown-it";
import sanitizeHtml from "sanitize-html";

const { message, source } = defineProps(["message", "source"]);
const classes = computed(() => {
  return {
    "message-container": true,
    "user-message": source === "user",
    "bot-message": source === "bot",
  };
});
const store = useStore();
const markdown = new MarkdownIt();

const userInitials = computed(() => {
  const name = store.state.auth.user.name;
  if (!name) return "";
  const initials = name
    .split(" ")
    .map((n) => n[0])
    .join("");
  return initials.toUpperCase();
});
</script>

<template>
  <div :class="classes">
    <v-avatar
      class="avatar"
      :color="source === 'bot' ? 'var(--blue-bonnet)' : 'var(--cambridge-blue)'"
      size="25px"
    >
      <span class="avatar-content">
        {{ source === "bot" ? "AI" : userInitials }}
      </span>
    </v-avatar>
    <span
      class="message"
      v-html="sanitizeHtml(markdown.render(message))"
    ></span>
  </div>
</template>

<style scoped lang="scss">
.message-container {
  display: flex;
  margin-top: 10px;
  margin-right: 10px;
  width: 100%;
  align-items: center;
  gap: 15px;
  justify-content: flex-start;

  .avatar {
    .avatar-content {
      color: white;
      font-size: 0.875rem;
    }
  }

  .message {
    padding: 10px 25px;
    max-width: 80%;
  }
}

.user-message {
  flex-direction: row-reverse;
  .message {
    border: 1px solid var(--grey);
  }
}

.bot-message {
  .message {
    background-color: var(--light-grey);
  }
}
</style>
