<script setup>

import {onMounted} from "vue";
import {useToast} from "vue-toastification";
import axios from "axios";
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";
import Page from "@/components/Page.vue";
const $t = useI18n().t;
const router = useRouter();

const error = ref(null);
const isLoading = ref(true);


const authInDocusign = async () => {
  isLoading.value = true;

  const authCode = router.currentRoute.value.query.code;

  try {
    const response = await axios.post("api/docusign/callback", {
      code: authCode
    });

    if (response.status === 201) {
      useToast().success($t("docusign.auth_success"));
      return router.push("/sign-documents");
    } else {
      useToast().error($t("notifications.default_error"));
    }
  } catch (error) {
    isLoading.value = false;
    useToast().error(error.response.data.error);
  }
}

onMounted(async () => {
  await authInDocusign();
});
</script>

<template>
<Page title="Docusign">
  <div class="loader">
      <v-progress-circular
        v-if="isLoading"
        indeterminate
        color="primary"
        bg-color="grey lighten-3"
        size="80"
      />
     <div v-else>
      <v-alert
        type="error"
        border="left"
        icon="mdi-alert-circle"
      >
        Failed to authenticate in Docusign
      </v-alert>
     </div>
  </div>
</Page>
</template>

<style scoped>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
}
</style>
