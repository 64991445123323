<script setup>
import { computed, ref } from "vue";
import axios from "axios";
import Page from "@/components/Page.vue";
import sanitizeHtml from "sanitize-html";

const apiHost = `${import.meta.env.VITE_APP_API_BASE_URL}/api`;

const { title, url } = defineProps(["title", "url"]);

const content = ref();
const faq = ref(null);
const query = ref("");
const isLoaded = ref(false);
const hasFile = ref(false);

const getStaticPage = async () => {
  try {
    const response = await axios.get(`api/static-pages/${url}`);
    const data = response.data.data;

    content.value = data.content;

    if (data.has_file) {
      hasFile.value = true;
    }

    if (data.faqs.length) {
      faq.value = data.faqs.map((item) => ({
        title: item.question,
        text: item.answer,
      }));
    }

    isLoaded.value = true;
  } catch (error) {
    isLoaded.value = true;
  }
};

const downloadFile = async () => {
  window.open(`${apiHost}/static-pages/${url}/download`, "_blank");
};

getStaticPage();

const visibleItems = computed(() => {
  return faq.value.filter((item) => {
    const inTitle = item.title
      .toLowerCase()
      .includes(query.value.toLowerCase());
    const inText = item.text.toLowerCase().includes(query.value.toLowerCase());
    return inText || inTitle;
  });
});
</script>
<template>
  <Page :title="title" :with-back-button="true">
    <template #buttons>
      <v-btn
        v-if="hasFile"
        @click="downloadFile"
        block
        variant="flat"
        class="download-button"
        :text="$t('common.download')"
      />
    </template>
    <div
      class="page-container"
      v-if="isLoaded"
      v-html="sanitizeHtml(content)"
    />
    <template v-if="faq">
      <div class="spacer mt-5 mb-5"></div>
      <v-text-field
        width="320"
        v-model="query"
        id="searchbox"
        variant="outlined"
        density="compact"
        :placeholder="$t('common.search')"
      >
        <template v-slot:append-inner>
          <img src="/src/assets/icons/search.svg" alt="Search" />
        </template>
      </v-text-field>

      <v-expansion-panels variant="accordion">
        <v-expansion-panel
          v-for="item in visibleItems"
          :key="item.title"
          :title="item.title"
          :text="item.text"
        ></v-expansion-panel>
      </v-expansion-panels>
    </template>
  </Page>
</template>

<style scoped>
.download-button {
  background-color: var(--blue-bonnet);
  color: white;
}
</style>
