<script setup>
import axios from "axios";
import {useRouter} from "vue-router";
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";

const props = defineProps(["isDialogOpen"]);
const emit = defineEmits(["closeDialog", "handleLogout"]);
const router = useRouter();
const toast = useToast();
const { t } = useI18n();

const deleteAccountReason = ref("");
const verifyCode = ref("");

const deleteAccount = () => {
  if(deleteAccountReason.value === "") {
    return toast.error(t("account.delete_account_reason_required"));
  }

  try {
    axios.delete("/api/user", {
      params: {
        reason: deleteAccountReason.value,
      },
    });
    emit("closeDialog");
    emit("handle-logout")
  } catch (error) {
    console.error(error);
  }
};
</script>

<template>
  <v-dialog
    v-model="props.isDialogOpen"
    max-width="600"
  >
    <v-card>
      <div class="close-container">
        <img @click="emit('closeDialog')" src="/images/icons/close.svg" alt="close dialog"/>
      </div>
      <v-card-title class="dialog-title">
        {{ $t('account.delete_account_message') }}
      </v-card-title>
      <v-card-text class="dialog-content">
        <p>{{ $t('account.delete_account_warning') }}</p>
        <v-text-field
          class="mt-3"
          v-model="verifyCode"
          :label="$t('account.delete_verify_code')"
          variant="outlined"
          required
        />

        <template v-if="verifyCode === 'DELETE'">
          <p>{{ $t('account.delete_account_reason_message') }}</p>
          <v-textarea
            class="mt-3"
            v-model="deleteAccountReason"
            :label="$t('account.delete_account_reason')"
            variant="outlined"
            required
          ></v-textarea>
        </template>
      </v-card-text>

      <v-divider class="mb-5"></v-divider>

      <v-card-actions>
        <v-btn
          color="error"
          :text="$t('common.delete')"
          variant="flat"
          :disabled="verifyCode !== 'DELETE'"
          @click="deleteAccount"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.dialog-title {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 23px 23px 0px;
  word-break: break-all !important;
  text-overflow: initial;
  white-space: normal;
}

.dialog-content {
  color: #858788;;
}

.close-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20px;

  img {
    cursor: pointer;
  }
}
</style>
