<script setup>
import {useStore} from "vuex";
import axios from "axios";
import {applyStyleToVariable} from "@/utils/editorStyling";
import {useIsMobile} from "@/utils/checkMobile";

const store = useStore();
const isHidden = ref(true);
const {isAiAssistantOpen, documentId} = defineProps([
  "isAiAssistantOpen",
  "documentId",
]);
const emit = defineEmits(["save", "closeEditor"]);

const description = computed(
  () => store.state.editor.editableVariable.description ?? ""
);
const label = computed(() => store.state.editor.editableVariable.name ?? "");
const type = computed(() => store.state.editor.editableVariable.type ?? "text");
const variableId = computed(
  () => store.state.editor.editableVariable.id ?? null
);

const value = computed({
  get() {
    return store.state.editor.editableVariable.value;
  },
  set(newValue) {
    store.commit("editor/updateEditableVariable", {
      name: label.value,
      value: newValue,
      answer: newValue,
      description: description.value,
      type: type.value,
      id: variableId.value,
    });
  },
});

const isMobile = useIsMobile();

const handleCloseEditor = () => {
  if (!isHidden.value) {
    store.commit("editor/updateEditableVariable", {
      name: "",
      value: "",
      description: "",
      type: "text",
      id: null,
      answer: "",
    });
    applyStyleToVariable(null);
    isHidden.value = true;
    emit('closeEditor')
  } else {
    isHidden.value = false;
  }
};

const saveValue = async () => {
  await axios.patch(`api/documents/${documentId}/meta/variables/answer`, {
    identifier: variableId.value,
    answer: value.value,
  });

  store.commit("editor/updateVariable", {
    id: variableId.value,
    value: value.value,
    answer: value.value,
  });

  handleCloseEditor();
};

watch(
  () => variableId.value,
  (newValue) => {
    if (newValue) {
      isHidden.value = false;
    }
  }
);
</script>

<template>
  <div
    v-if="value"
    class="variable-inputs-container"
  >
    <!-- Mobile View -->
    <template v-if="isMobile">
      <h3 v-if="description !== ''">{{ description }}</h3>
      <v-card v-if="description !== ''">
        <span class="input-label"> {{ label }} </span>
        <v-text-field
          variant="outlined"
          v-model="value"
          :placeholder="label"
          :type="type"
        ></v-text-field>
      </v-card>
        <v-btn
          v-if="label"
          @click="saveValue"
          class="mobile-save-button"
          variant="flat"
        >
          {{ $t("common.save") }}
        </v-btn>
    </template>

    <!-- Desktop View -->
    <template v-else>
      <h3 v-if="description !== ''">{{ description }}</h3>
      <v-card v-if="description !== ''">
        <span class="input-label"> {{ label }} </span>
        <v-text-field
          variant="outlined"
          v-model="value"
          :placeholder="label"
          :type="type"
        ></v-text-field>
      </v-card>
      <div class="wizard-buttons">
        <v-btn
          v-if="label"
          @click="saveValue"
          class="forward-button"
          variant="flat"
        >
          {{ $t("common.save") }}
        </v-btn>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
:deep(.v-card-item) {
  padding: 0;
  margin-top: 20px;
}

:deep(.v-card-text) {
  padding: 0;
  margin-top: 5px;
}

.v-card {
  border: none;
}

.variable-inputs-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  min-height: 50%;
  padding: 10px;
  transition: 0.3s ease-in-out;
  overflow: hidden;

  .input-label {
    font-size: 0.875rem;
    color: var(--eerie-black);
  }

  :is(h3) {
    font-size: 0.875rem;
    font-weight: 600;
  }

  .variable-inputs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    border: #111111 1px solid;
    height: 80%;
    padding: 20px;

    .single-input {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .single-input__label {
        font-weight: 400;
        padding-top: 8px;
        text-align: left;
        text-transform: capitalize;
      }

      input {
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #111111;
        width: 60%;
      }
    }
  }
}
.wizard-buttons {
  display: flex;
  justify-content: end;
  gap: 16px;

  .forward-button {
    background-color: var(--blue-bonnet) !important;
    color: white !important;
  }

  .forward-button,
  .back-button {
    text-decoration: none;
    color: #858788;
    font-size: 0.688rem;

    :is(img) {
      width: 5px;
    }

    &:hover {
      color: black;
    }
  }

  .forward-button {
    :is(img) {
      transform: rotate(180deg);
    }
  }
}

.mobile-save-button {
  color: white;
  background: var(--cambridge-blue);
  margin: 25px 50px 50px;
  font-size: 1.125rem;
}
</style>
