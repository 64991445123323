import parseStyles from "../parseStyles";

const parseMarks = (xmlNode, accumulatedMarks = []) => {
  const marks = [...accumulatedMarks];

  switch (xmlNode.nodeName.toLowerCase()) {
    case "strong":
      marks.push({ type: "strong" });
      break;
    case "u":
      marks.push({ type: "underline" });
      break;
    case "em":
      marks.push({ type: "em" });
      break;
  }

  if (!xmlNode.getAttribute) {
    return marks;
  }

  const style = xmlNode.getAttribute("style");

  if (style) {
    const { fontSize, fontFamily, textColor, backgroundColor, lineHeight } =
      parseStyles(style);

    fontSize && marks.push({ type: "fontSize", attrs: { fontSize } });
    fontFamily && marks.push({ type: "fontFamily", attrs: { fontFamily } });
    textColor && marks.push({ type: "textColor", attrs: { textColor } });
    backgroundColor &&
      marks.push({ type: "backgroundColor", attrs: { backgroundColor } });
    lineHeight && marks.push({ type: "lineHeight", attrs: { lineHeight } });
  }

  return marks;
};

export default function parseText(xmlNode, accumulatedMarks = []) {
  const marks = parseMarks(xmlNode, accumulatedMarks);

  if (xmlNode.nodeType === Node.TEXT_NODE) {
    if (!xmlNode.textContent) {
      return null;
    }

    const textNode = {
      type: "text",
      text: xmlNode.textContent,
    };

    if (marks.length > 0) {
      textNode.marks = marks;
    }

    return textNode;
  }

  for (let i = 0; i < xmlNode.childNodes.length; i++) {
    const result = parseText(xmlNode.childNodes[i], marks);
    if (result) {
      return result;
    }
  }

  return null;
}
