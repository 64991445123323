import convertNode from "../convertNode";
import showNodeConditionally from "../../showNodeConditionally";

export default function parseTableCell(xmlNode) {
  if (showNodeConditionally(xmlNode)) {
    return {
      type: 'table_cell',
      content: Array.from(xmlNode.children).map(convertNode).filter(n => n),
    }
  } else {
    return null;
  }
}
