<script setup>
import ContactForm from "@/components/contact/ContactForm.vue";

const props = defineProps(["isDialogOpen"]);
const emit = defineEmits(["closeDialog"]);
</script>

<template>
  <v-dialog
    v-model="props.isDialogOpen"
    max-width="600"
  >
    <ContactForm>
      <template #close>
        <div class="close-container">
          <img @click="emit('closeDialog')" src="/images/icons/close.svg" alt="close dialog"/>
        </div>
      </template>
    </ContactForm>
  </v-dialog>
</template>

<style scoped lang="scss">
.close-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20px;

  img {
    cursor: pointer;
  }
}
</style>
