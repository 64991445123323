<script setup>
import axios from "axios";
import {useRouter} from "vue-router";
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";

const props = defineProps(["isDialogOpen", "documentId", "documentName"]);
const emit = defineEmits(["closeDialog", "refreshDocuments"]);
const router = useRouter();
const toast = useToast();
const { t } = useI18n();

const deleteDocument = async () => {
  try {
    await axios.delete(`/api/documents/${props.documentId}`);
    emit("refreshDocuments");
    emit("closeDialog");
    toast.success(t("documents.delete_success"));
  } catch (error) {
    console.error(error);
  }
};
</script>

<template>
  <v-dialog
    v-model="props.isDialogOpen"
    max-width="500"
  >
    <v-card>
      <div class="close-container">
        <img @click="emit('closeDialog')" src="/images/icons/close.svg" alt="close dialog"/>
      </div>
      <v-card-title class="dialog-title">
        {{ $t('documents.delete_document', {name: documentName}) }}
      </v-card-title>

      <v-card-text class="dialog-content">
        {{ $t('documents.delete_document_description') }}
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="error"
          :text="$t('common.confirm')"
          variant="flat"
          @click="deleteDocument"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.dialog-title {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 23px 23px 0px;
  white-space: normal;
}

.dialog-content {
  color: #858788;;
}

.close-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20px;

  img {
    cursor: pointer;
  }
}
</style>
