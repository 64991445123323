export const en = {
  auth: {
    sign_in: "Sign in",
    signing_in: "Login",
    signing_up: "Register",
    sign_up: "Register",
    sign_up_promo:
      "Join Legal Torch to gain access exclusive features and benefits!",
    login: "Login",
    logout: "Log out",
    register: "Register",
    password: "Password",
    forgot_password: "Password recovery",
    forgot_password_link: "Forgot your password?",
    confirm_password: "Repeat password",
    create_account: "Don't have an account?",
    already_have_account: "Already have an account?",
    reset_password: "Reset password",
    "Reset Password": "Reset Password",
    recaptcha: "Please tick the reCAPTCHA checkbox",
    i_have_read: "I have read and accept the {terms} and {privacyPolicy}",
    remember_me: "Remember me",
    remember_me_hint: "Stay logged in for 7 days",
    remember_the_password: "Remember the password?",
    need_help: "Need help?",
  },
  invoice: {
    address: "Address",
    city: "City",
    zip_code: "Postcode",
    country: "Country",
    tin_number: "TIN",
  },
  chat: {
    ai_assistant: "AI Legal Assistant",
    new_document_welcome_message:
      "Not sure which document to choose? I'm here to help! Just ask me anything.",
    do_you_need_help: "Need help?",
    ask_legal_assistant: "Ask your AI Legal Assistant",
    virtual_assistant: "Virtual Assistant",
    ai_legal_assistant: "AI Legal Assistant",
    lawyer: "Lawyer",
    message_placeholder: "Got a question? I'm here to help!",
    creator_welcome_message:
      "Hello there! I'm your AI Legal Assistant. I'm here to help - just ask me anything!",
    disclaimer:
      "This message was generated by AI and should not be considered legal advice.",
    disclaimer_1:
      "To use the Virtual Assistant, please read the following information:",
    disclaimer_2:
      "Do you understand Legal Torch is a contract resource and generation tool, not a provider of legal or professional advice or services? You should consult a professional for specific legal guidance, as Legal Torch is not a substitute for legal advice or services.",
    disclaimer_3:
      "Remember my settings so that I don't have to confirm this consent in the future",
    disclaimer_4: "Yes, I understand",
    sample_messages: ["Summarize document", "List the main points"],
  },
  billing: {
    plan: "Plan",
    next_payment: "Next payment",
    subscription_cancelled: "Successfully canceled",
    subscription_resumed: "Successfully resumed",
    subscription: "Subscription details",
    starting_from: "Starting from",
    available_plans: "Available plans",
    subscription_coverage: "Subscription coverage",
    extended_subscription: "Extended subscription",
    choose_subscription: "Choose a subscription plan",
    cancel_subscription: "Cancel subscription",
    cancel_subscription_message:
      "Are you sure you want to unsubscribe? We'd be sorry to see you go!",
    payment_history: "Payment history",
    payment_method: "Payment method",
    payment_date: "Payment date",
    payment_required: "Payment required",
    payment_subtitle: "Please complete your payment to finalize the document.",
    per_month: "per month",
    per_document: "Per document",
    buy_now: "Buy now",
    total: "Total",
    plans_benefits1: [
      [
        "Try our platform for 12 months",
        "Unlimited document creation",
        "Full access to all features",
      ],
      [
        "Fully utilize our platform's capabilities",
        "Balance between flexibility and long-term advantage",
        "Our most popular plan for flexibility and value",
      ],
      [
        "Our most popular plan for flexibility and value",
        "Fully integrate tools into your processes",
        "Best choice for long-term efficiency and savings",
      ],
    ],
    extended_option:
      "Flexible option - purchase a single document. Choose a template, make your payment and get instant access.",
    selected_templates: "Selected templates",
    selected_templates_details:
      "(editing, personalisation, printing, downloading, sharing, negotiation mode, electronic signature)",
    ai_assistant: "AI Assistant in selected templates",
    ai_assistant_details:
      "Limited access to the assistant: search for contracts - 20,000 characters/month and help to personalise the document - 100,000 characters/month",
    limited_dashboard: "Restricted access to user dashboard",
    limited_dashboard_details: "(no folder creation available)",
    single_document: "Single Document",
    free_plan: "Free Plan",
    free_subscription:
      "You're on the Free Plan! Enjoy searching for documents and using free templates with basic editing. Want more features? Upgrade your plan or purchase individual documents anytime!",
    free_subscription_details: "Free templates",
    free_subscription_details_info:
      "(edit, personalise, print, download, share, negotiation mode, electronic signature)",
    free_subscription_ai_assistant: "AI Legal Assistant",
    free_subscription_ai_assistant_info:
      "(in free templates - limited access to the assistant: Searching for contracts - 10,000 characters/month Help in personalizing the document - 20,000 characters/month)",
    free_subscription_dashboard: "Limited access to user dashboard",
    free_subscription_dashboard_details:
      "(without the possibility of creating folders)",
    join_premium_subscription:
      "Join our Premium Plan for unlimited access to all templates, full Assistant support and a complete dashboard to manage your contracts effortlessly. Thank you for choosing to subscribe!",
    premium_plan: "Premium Plan",
    premium_subscription:
      "You've subscribed to our Premium Plan🎉! Enjoy unlimited access to our full document library, personalised AI Legal Assistant support and a comprehensive dashboard to manage your contracts effortlessly. Thank you for being a valued member of our community!",
    premium_subscription_details:
      "Enjoy unlimited access to all templates for the duration of your subscription!",
    premium_subscription_details_info:
      "(Editing, personalisation, printing, downloading, sharing, negotiation mode, duplicating contracts)",
    premium_subscription_ai_assistant: "Unlimited access to AI Legal Assistant",
    premium_subscription_dashboard: "Full access to user dashboard",
    traditional_payments: "Card payments",
    crypto_payments: "Pay with VAI Utility Token",
  },
  documents: {
    new_document: "New document",
    new_document_instruction: "Guide",
    new_document_instruction_step_1:
      "1. Choose a template that suits your needs.",
    new_document_instruction_step_2:
      "2. Need help choosing a template? Explain your situation to the AI Legal Assistant and it will recommend the appropriate document. You can also use our search function to find a specific document by name!",
    new_document_instruction_step_3:
      "3. Fill in the document with your information.",
    new_document_instruction_step_4:
      "4. If required, you can sign the document electronically.",
    popular_templates: "Popular templates",
    my_documents: "My Documents",
    use_template: "Use a template",
    create_document: "New document",
    create_directory: "New folder",
    remove_directory: "Remove folder",
    no_documents: "No documents available.",
    new_directory_subtitle: "Please enter a name for the folder",
    move_to_directory: "Move to folder",
    remove_from_directory: "Remove from folder",
    move_to_new_directory: "Move to a new folder...",
    show_history: "Show history",
    change_history: "History of changes",
    current_version: "Current version",
    previous_version: "Previous version",
    next_version: "Next version",
    send_to_verification: "Send for verification",
    preview: "Preview",
    name: "Document name",
    folder_name: "Folder name",
    type: "Type",
    status: "Status",
    statuses: {
      Draft: "Draft",
      "Ready for review or signature": "Ready for review or signature",
      Negotiation: "Negotiation",
      "Ready for signature": "Ready for signature",
      Signed: "Signed",
    },
    signatures: {
      sign: "Sign",
      sign_document: "Sign document",
      sign_documents: "Sign documents",
      documents_to_sign: "Documents to sign",
      upload_new_document_and_sign: "Upload and sign a new document",
      pdf_files_digitally: "Digitally sign PDF files",
      select_a_document_from_the_platform:
        "Choose a document from the platform",
      select_a_document_from_your_device: "Select a document from your device",
      or_drag_and_drop_here: "Or drag and drop here",
      document_size: "Document size",
      document_extension: "Document extension",
      signing_format: "Signature format",
      pdf_file_in_pades: "PDF file in PAdES format",
      go_sign: "Go to signature",
      other_in_xades: "Other in XAdES format",
      learn_more_about_pades_and_xades_formats:
        "Find out more about PAdES and XAdES formats",
      recommended_browsers: "Recommended browsers",
      show_ready_documents: "Review your documents, ready for signature",
    },
    duplicate: "Duplicate",
    accept_document: "Accept document",
    document_accepted: "Document accepted",
    negotiation_header: "Welcome to Negotiation Mode – let’s get started!",
    negotiation_description:
      "In this module, you can add comments to the document or specific sections to suggest changes. If the document meets your expectations, click the button below to accept it. The other party will be notified automatically of your decision. Start negotiating and collaborate to reach the best outcome!",
    subscription_or_single_payment_required:
      "To edit this document, please repurchase it or upgrade to a subscription plan.",
    delete_document: "You are trying to delete document \"{name}\"",
    delete_document_description: "Are you sure you want to proceed?",
    delete_success: "Document deleted successfully",
    show_comments: "Show comments",
    sign_hint: "Finish filling out the contract using the form",
  },
  common: {
    email: "Email",
    name: "Name and Surname",
    phone: "Phone Number",
    or: "or",
    and: "and",
    edit: "Edit",
    file: "File",
    share: "Share",
    search_bar_placeholder: "Search / Ask the AI Legal Assistant",
    home: "Home",
    new_document: "New document",
    documents: "Documents",
    profile: "Profile",
    guest: "Guest",
    search: "Search",
    created_at: "Created on",
    show: "Show",
    preview: "Preview",
    hide: "Hide",
    send: "Send",
    save: "Save",
    load_more: "Load more",
    cancel: "Cancel",
    create: "Create",
    upgrade_subscription: "Upgrade Plan",
    upgrade: "Upgrade",
    confirm: "Confirm",
    confirm_changes: "Do you want to save your changes?",
    discard: "Discard",
    resume: "Resume",
    manage: "Manage",
    close: "Close",
    choose: "Select",
    status: "Status",
    starts_at: "Subscription from",
    ends_at: "Subscription ends",
    previous: "Previous",
    next: "Next",
    save_as: "Save as",
    print: "Print",
    delete: "Delete",
    move: "Move",
    directory: "Directory",
    reset: "Reset",
    requiresAuth: "Authorisation required",
    requiresAuthMessage: "You must be logged in to access this feature",
    subscribe: "Subscribe",
    subscriptionRequired: "Subscription required",
    subscriptionRequiredMessage: "Subscribe to access this feature.",
    subscriptionOrSinglePaymentRequired: "Payment required",
    subscriptionOrSinglePaymentRequiredMessage:
      "Pay for the document to be completed",
    notifications: "Notifications",
    youAreUpToDate: "You are up to date",
    markAllAsRead: "Mark all as read",
    dateOfCreation: "Creation date",
    type: "Type",
    amount: "Amount",
    restore: "Restore",
    from: "From",
    back: "Back",
    forward: "Next",
    select: "Select",
    sign: "Sign",
    menu: "Menu",
    buy: "Buy now",
    findDocument: "Find",
    download: "Download",
    sent_at: "Sent on",
    opened_at: "Opened on",
    history_of_sharing: "Sharing history",
    settings: "Settings",
  },
  creator: {
    new_document: "New document",
    wysiwyg_wizard_locked: "Please answer some questions first",
    wizard: "Wizard",
    form: "Request form",
    copy_link: "Copy link",
    share_email: "Share by email",
    send: "Send for verification",
    change_document_name: "Change document name",
    change_document_name_description:
      "Please enter a new name for the document",
    toolbar: {
      font: "Font",
      format: "Format",
      color: "Color",
      spacing: "Spacing",
      size: "Size",
      text: "Text",
      background: "Background",
      line_height: "Line Height",
    },
  },
  notifications: {
    default_error: "Oops! Something went wrong.",
    default_update_success: "Updated successfully!",
    email_change:
      'Verify your new email address "{email}" to complete the change.',
    login_success: "Logged in successfully!",
    login_error: "Invalid credentials",
    logout_success: "Logged out successfully!",
    register_success: "Registered successfully!",
    auth_error_account_exists:
      "There is already an account with the information you have entered. Please login to continue.",
    document_moved: "Document successfully moved",
    document_saved: "Document successfully saved",
    directory_removed: "Directory successfully removed",
    directory_created: "Directory successfully created",
    link_copied: "Link copied to clipboard",
    password_reset_success: "Password reset successful",
    forgot_password_success:
      "If your email is registered, you'll receive a link to reset your password shortly.",
    document_deleted: "Document successfully deleted",
    document_save_error: "Error saving document",
    chat_char_limit:
      "You've reached your chat limit for the last 30 days. Please check back later!",
    logout_warning:
      "You'll be automatically logged out soon due to inactivity.",
    document_duplicated: "Document duplicated successfully",
    document_duplicate_error:
      "Oops! We were unable to duplicate the document. Please try again.",
    document_name_updated: "Document name updated successfully",
    wizard_completed:
      "Document finished! 🎉 Your document has been successfully saved. Go to the 'My Documents' section to manage it. You can share it for negotiation, download it, print it or sign it!",
  },
  errors: {
    global: {
      too_many_request:
        "You've made too many requests. Please take a moment and try again in a minute.",
    },
    documents: {
      not_found: "No documents found",
      move_error: "Error moving document",
      save_error: "Error saving document",
      directory_removal_error: "Error removing directory",
      directory_creation_error: "Error creating directory",
      name_required: "Document name required",
      document_delete_error: "Error deleting document",
    },
    auth: {
      terms_required:
        "Please accept the Privacy Policy and Terms and Conditions.",
      invalid_credentials: "Incorrect email or password",
    },
    search: {
      no_categories: "Could not load category list",
    },
    editor: {
      prohibited_action: "You cannot remove this content",
      variable_value_required: "You must enter a value for this field!",
    },
    chat: {
      limit_general:
        "You have reached the chat limit for the last 30 days. Buy a subscription to continue chatting.",
      limit_document:
        "You have reached the chat limit for this document. Buy a subscription to continue chatting.",
    },
  },
  static_pages: {
    "Cookies Policy": "Cookies Policy",
    "Privacy Policy": "Privacy Policy",
    "Terms Of Service": "Terms and Conditions",
    "About Us": "About Us",
    "Frequently Asked Questions": "Frequently Asked Questions",
    "AI Disclaimer": "AI Disclaimer",
    "Ai Disclaimer": "AI Disclaimer",
  },
  footer: {
    credits: "©2024 VAIOT Limited",
  },
  home: {
    active_documents: "Active Documents",
    latest_documents: "Latest Documents",
    latest_documents_mobile: "Recently created documents",
  },
  account: {
    delete_account: "Delete account",
    delete_account_message: "Are you sure you want to delete your account?",
    delete_account_warning: "Attention! This action is irreversible.",
    delete_verify_code: 'Enter "DELETE" to confirm account deletion',
    delete_account_reason_message: "Please specify the reason for deletion.",
    delete_account_reason: "Reason for deletion",
    delete_account_reason_required:
      "Please enter a reason for account deletion.",
    change_password: "Change password",
    current_password: "Current password",
    new_password: "New password",
    confirm_new_password: "Confirm new password",
    password_changed: "Password changed successfully",
  },
  email_popup: {
    title: "Complete your account registration",
    description:
      "You're almost there! Complete your registration to effortlessly manage all your documents in one place. Save time and stay organised!",
    button: "Complete registration",
  },
  profile: {
    user_profile: "My account",
    account_details: "Account details",
    change_password: "Change password",
    contact_details: "Contact details",
    invoice_details: "Invoice details",
  },
  wizard: {
    progress: "Progress",
  },
  contact_form: {
    contact_support: "Contact support",
    name: "Name",
    email: "Email",
    subject: "Subject",
    describe_your_problem: "Describe your problem",
    send: "Send",
    message_sent_successfully: "Message sent successfully",
    share_your_feedback: "Share your feedback",
  },
  docusign: {
    auth_success: "DocuSign authorisation successfully completed",
    connect_your_account: "Connect your DocuSign account",
  },
  comments: {
    title: "Comments",
    add_comment: "Add a comment",
    comment_placeholder: "Type your comment here...",
    comment_added: "Comment successfully added",
    comment_removed: "Comment successfully removed",
    comment_remove_error: "Error: Unable to remove comment",
    comment_add_error: "Error: Unable to add comment",
  },
};
