
			//:::::::UNICONSENT:::::::
			(() => {
				const s1 = document.createElement("script");
				s1.src = "https://cmp.uniconsent.com/v2/stub.min.js";
				document.head.appendChild(s1);

				const s2 = document.createElement("script");
				s2.src = "https://cmp.uniconsent.com/v2/stubgcm.min.js";
				document.head.appendChild(s2);

				const s3 = document.createElement("script");
				s3.async = true;
				s3.src = "https://cmp.uniconsent.com/v2/5d3b59773e/cmp.js";
				document.head.appendChild(s3);
			})();
			//:::::::END UNICONSENT:::::::
		