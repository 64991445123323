<script setup>
import axios from "axios";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";

const emit = defineEmits(["onCreate"]);

const dialog = defineModel({
  required: true,
  type: Boolean,
});

const name = ref("");

const $t = useI18n().t;

const createDirectory = async () => {
  try {
    const response = await axios.post("/api/directories", {
      name: name.value,
    });

    dialog.value = false;
    name.value = "";

    const newDirectoryId = response.data.data.id;
    emit("onCreate", newDirectoryId);

    useToast().success($t("notifications.directory_created"));
  } catch (error) {
    useToast().error($t("errors.documents.directory_creation_error"));
  }
};
</script>

<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-form @submit.prevent="createDirectory">
      <v-card
        prepend-icon="mdi-folder-multiple-outline"
        :title="$t('documents.create_directory')"
      >
        <template v-slot:text>
          <div class="mb-2">{{ $t("documents.new_directory_subtitle") }}</div>
          <v-text-field
            v-model="name"
            :label="$t('documents.folder_name')"
            required
          ></v-text-field>
        </template>

        <template v-slot:actions>
          <v-btn @click="dialog = false"> {{ $t("common.cancel") }} </v-btn>

          <v-spacer></v-spacer>

          <v-btn type="submit" color="primary" variant="elevated">
            {{ $t("common.create") }}
          </v-btn>
        </template>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
