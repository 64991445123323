<script setup>
import axios from "axios";
import { useStore } from "vuex";

const { user } = defineProps(["user"]);
const emit = defineEmits(["setVisibility"]);

const store = useStore();

const isCheckedPerm = ref(false);

const handleSave = async () => {
  const apiUrl = import.meta.env.VITE_APP_API_BASE_URL;

  try {
    const response = await axios.post(`${apiUrl}/api/user/accept-disclaimer`, {
      is_accepted_temporarily: isCheckedPerm.value,
    });

    emit("setVisibility", false);
  } catch (error) {
    console.error(error);
  }
};

onMounted(() => {
  const isDisclaimerAccepted = user?.disclaimer;

  if (!isDisclaimerAccepted) {
    return emit("setVisibility", true);
  }

  const { accepted_at, is_accepted_temporarily } = user.disclaimer;

  const threeDays = 259200000;
  const oneYear = 31557600000;

  const diff = Date.now() - accepted_at * 1000;

  if (diff > oneYear || (diff > threeDays && !is_accepted_temporarily)) {
    return emit("setVisibility", true);
  }

  return emit("setVisibility", false);
});
</script>
<template>
  <div class="disclaimer">
    <p class="title">{{ $t("chat.disclaimer_1") }}</p>
    <p class="description">{{ $t("chat.disclaimer_2") }}</p>
    <div class="input">
      <input
        class="checkbox"
        v-model="isCheckedPerm"
        id="checkbox"
        type="checkbox"
      />
      <label class="label" for="checkbox">{{ $t("chat.disclaimer_3") }}</label>
    </div>

    <VBtn
      @click="handleSave"
      type="button"
      class="accept-button"
      variant="flat"
    >
      {{ $t("chat.disclaimer_4") }}
    </VBtn>
  </div>
</template>
<style scoped lang="scss">
.disclaimer {
  padding: 55px 30px;
  .title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .description {
    margin-bottom: 20px;
    font-size: 11px;
  }

  .input {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    gap: 10px;

    .label {
      font-size: 11px;
      opacity: 1;
      font-weight: 500;
      user-select: none;
      &:hover {
        cursor: pointer;
        opacity: 0.9;
      }
    }
    .checkbox {
      cursor: pointer;
    }
  }

  .accept-button {
    font-size: 1.125rem;
    color: white !important;
    background-color: var(--cambridge-blue);
    text-transform: none;
    border-radius: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    height: 45px;
    margin-top: 35px;
  }
}
@media (max-width: 1050px) {
  .disclaimer {
    .accept-button {
      margin-top: 0;
    }
  }
}
@media (max-height: 850px) {
  .disclaimer {
    padding: 10px 10px;
    .title {
      font-size: 12px;
      margin-bottom: 10px;
    }
    .description {
      margin-bottom: 10px;
    }
    .input {
      margin-bottom: 10px;
    }
    .accept-button {
      margin-top: 10px;
    }
  }
}
</style>
