export const documentStatuses = [
  {
    name: 'Draft',
    value: 'draft',
    color: 'red'
  },
  {
    name: 'Ready for review or signature',
    value: 'ready_for_review_or_signature',
    color: 'orange'
  },
  {
    name: 'Negotiation',
    value: 'negotiation',
    color: 'blue'
  },
  {
    name: 'Ready for signature',
    value: 'in_verification',
    color: 'blue'
  },
  {
    name: 'Signed',
    value: 'signed',
    color: 'green'
  }
];
