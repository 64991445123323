<template>
  <v-dialog v-model="props.isDialogOpen" width="400">
    <v-card :title="$t('common.subscriptionRequired')">
      <v-card-text>
        {{ $t('common.subscriptionRequiredMessage') }}
      </v-card-text>

      <v-card-actions>
        <v-btn
          :text="$t('common.cancel')"
          variant="tonal"
          @click="emit('closeDialog')"
        ></v-btn>

        <v-spacer></v-spacer>

        <v-btn
          @click="router.push('/profile#subscription')"
          :text="$t('common.subscribe')"
          type="submit"
          color="primary"
          variant="flat"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import router from "@/router";

const props = defineProps(['isDialogOpen']);
const emit = defineEmits(['closeDialog']);
</script>
