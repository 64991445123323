<script setup>
import DocumentNavigator from "@/components/documents/navigator/DocumentNavigator.vue";
import EditorHistoryModal from "@/components/ProseMirror/EditorHistoryModal.vue";

import { useIsMobile } from "@/utils/checkMobile";
import { useI18n } from "vue-i18n";

const { document, documentId } = defineProps(["document", "documentId"]);

const { t } = useI18n();

const isMobile = useIsMobile();
const showHistory = ref(false);

const showDescription = ref(!isMobile);

const toggleDescription = () => {
  showDescription.value = !showDescription.value;
};

const toggleHistoryModal = () => {
  showHistory.value = !showHistory.value;
};
</script>
<template>
  <div class="page-info">
    <div class="page-description">
      <h4>{{ t("documents.negotiation_header") }}</h4>

      <Transition>
        <p v-if="showDescription" class="negotiation-description">
          {{ t("documents.negotiation_description") }}
        </p>
      </Transition>

      <v-btn
        v-if="isMobile"
        variant="plain"
        density="compact"
        @click="toggleDescription"
      >
        {{ showDescription ? "Hide" : "Read more..." }}
      </v-btn>
    </div>

    <DocumentNavigator
      v-if="!isMobile"
      :document="document"
      :has-wizard-completed="true"
      :buttons="['visitor']"
      @show-history-modal="toggleHistoryModal"
    />

    <EditorHistoryModal
      v-if="showHistory"
      :documentId="documentId"
      :is-history-open="showHistory"
      @close="toggleHistoryModal"
      :with-restore="false"
    />
  </div>
</template>
<style scoped lang="scss">
.page-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.page-description {
  margin-right: 50px;
}

:deep(.v-btn--size-default) {
  padding: 0;
}
:deep(.v-btn__content) {
  display: block;
  text-align: left;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
