import store from "@/store/store";
import { escapeObjectValues } from "@/utils/escapeXML";

export default function parseMeta(meta, metaContent) {
  if (!metaContent) {
    return meta;
  }
  const titleNode = document.createElement("title");
  const titleTextNode = document.createTextNode(metaContent.title);
  titleNode.appendChild(titleTextNode);
  meta.appendChild(titleNode);

  const variablesNode = document.createElement("variables");
  metaContent.variables.forEach((rawVariable) => {
    const variable = escapeObjectValues(rawVariable);
    const { id, name, answer, type, description, pattern, options, example } =
      variable;

    //TODO dodac reszte OPCJONALNYCH atrybutów
    const variableNode = document.createElement("variable");
    variableNode.setAttribute("id", id);
    variableNode.setAttribute("name", name);
    variableNode.setAttribute("type", type);
    variableNode.setAttribute(
      "value",
      answer ? answer : store.state.editor.variables[variable.id]
    );

    if (description) {
      variableNode.setAttribute("description", description);
    }

    if (pattern) {
      variableNode.setAttribute("pattern", pattern);
    }

    if (options) {
      variableNode.setAttribute("options", options.join(";"));
    }

    if (example) {
      variableNode.setAttribute("example", example);
    }

    variablesNode.appendChild(variableNode);
  });

  meta.appendChild(variablesNode);

  const stepsNode = document.createElement("steps");
  metaContent.wizard.steps.forEach((rawStep) => {
    const step = escapeObjectValues(rawStep);
    const stepNode = document.createElement("step");
    stepNode.setAttribute("id", step.id);
    stepNode.setAttribute("description", step.description);
    step.subSteps.forEach((subStep) => {
      const subStepNode = document.createElement("subStep");
      subStepNode.setAttribute("id", subStep.id);

      subStep.questions.forEach((question) => {
        const questionNode = document.createElement("question");
        questionNode.setAttribute("id", question.id);
        questionNode.setAttribute("type", question.type);
        questionNode.setAttribute("content", question.content);
        questionNode.setAttribute(
          "answer",
          store.state.editor.wizard.answers.find(
            (a) => a.questionId === question.id
          ).answer
        );
        subStepNode.appendChild(questionNode);
      });
      stepNode.appendChild(subStepNode);
    });
    stepsNode.appendChild(stepNode);
  });
  meta.appendChild(stepsNode);

  return meta;
}
