import store from '@/store/store';

export default function showNodeConditionally(xmlNode) {
  function evaluateCondition(questionId, operator, value, wizardAnswers) {
    const chosenAnswer = wizardAnswers.find((item) => item.questionId === questionId);

    if (!chosenAnswer) {
      return false;
    }

    switch (operator) {
      case '===':
      case '==':
      case '=':
        return chosenAnswer.answer === value;
      case '!==':
      case '!=':
        return chosenAnswer.answer !== value;
      default:
        throw new Error(`Unsupported operator: ${operator}`);
    }
  }

  function parseConditions(conditions) {
    const tokens = conditions.split(/(;|\(|\))/).filter(Boolean);
    const stack = [];
    let current = [];

    tokens.forEach(token => {
      token = token.trim();
      if (token === 'AND' || token === 'OR') {
        current.push(token);
      } else if (token === '(') {
        stack.push(current);
        current = [];
      } else if (token === ')') {
        const nested = current;
        current = stack.pop();
        current.push(nested);
      } else if (token === ';') {
        // do nothing
      } else {
        current.push(token.split(','));
      }
    });

    return current;
  }

  function evaluateParsedConditions(parsedConditions, wizardAnswers) {
    if (typeof parsedConditions[0] === 'string') {
      const [questionId, operator, value] = parsedConditions;
      return evaluateCondition(questionId, operator, value, wizardAnswers);
    }

    let result = null;
    let currentOperator = null;

    parsedConditions.forEach(condition => {
      if (condition === 'AND' || condition === 'OR') {
        currentOperator = condition;
      } else {
        const evalResult = evaluateParsedConditions(condition, wizardAnswers);
        if (result === null) {
          result = evalResult;
        } else if (currentOperator === 'AND') {
          result = result && evalResult;
        } else if (currentOperator === 'OR') {
          result = result || evalResult;
        }
      }
    });

    return result;
  }

  function isAnswerCorrect(conditions) {
    if (!conditions) {
      return true;
    }

    const wizardAnswers = store.state.editor.wizard.answers;
    const parsedConditions = parseConditions(conditions);

    return evaluateParsedConditions(parsedConditions, wizardAnswers);
  }

  const conditions = xmlNode.getAttribute('conditions');
  if (!conditions) {
    return true;
  }

  return isAnswerCorrect(conditions);
}
