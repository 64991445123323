<script setup lang="ts">
import { Directory } from "@/types/documents/Directory";
import ButtonLink from "@/components/partials/ButtonLink.vue";

const emit = defineEmits(["setCurrentDirectory"]);

const { directory } = defineProps<{
  directory: Directory;
}>();

const setDirectory = () => {
  emit("setCurrentDirectory", directory.id);
};
</script>

<template v-if="directory">
  <v-col cols="12" xl="3" md="4" sm="12">
    <v-card>
      <template v-slot:prepend>
        <img
          src="/src/assets/icons/documents-list.svg"
          alt="Documents list"
          class="menu-icon"
        />
      </template>
      <v-card-title>
        <span class="directory-name">{{ directory.name }}</span>
      </v-card-title>
      <v-card-text>
        <div class="d-flex justify-space-between mt-3">
          <div class="directory-info text-capitalize">
            {{ $t("common.documents") }}: {{ directory.documents_count }}
          </div>
          <ButtonLink @click="setDirectory" class="directory-info">
            {{ $t("common.preview") }}
          </ButtonLink>
        </div>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<style scoped lang="scss">
.directory-name {
  font-size: 0.875rem;
  font-weight: 500;
}

.directory-info, .directory-info {
  font-size: 0.688rem;
  font-weight: 500;
}
</style>
