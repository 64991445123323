<script setup>
import Page from "@/components/Page.vue";
import MobileChat from "@/components/mobile/MobileChat.vue";
import axios from "axios";
import router from "@/router";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const initialMessages = ref([
  {
    id: crypto.randomUUID(),
    source: "bot",
    message: "Hello! How can I help you today?",
  },
]);

const documentId = computed(() => {
  return router.currentRoute.value.params.documentId;
});

const getDocumentById = async () => {
  const response = await axios.get(`api/documents/${documentId.value}`);

  return response.data.data;
};

const getChatHistory = async () => {
  if (documentId.value) {
    const response = await axios.get(
      `api/documents/${documentId.value}/chat-messages`
    );
    initialMessages.value =
      response.data.data.length === 0
        ? [
            {
              id: crypto.randomUUID(),
              source: "bot",
              message: t("chat.creator_welcome_message"),
            },
          ]
        : response.data.data;
  }
};

onMounted(() => {
  getChatHistory();
  getDocumentById();
});
</script>

<template>
  <Page title="AI Legal Assistant" :with-back-button="true">
    <MobileChat
      ref="legalChat"
      :initial-messages="initialMessages"
      :current-document="document"
    />
  </Page>
</template>

<style scoped lang="scss"></style>
