<script setup>
import axios from "axios";
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";

const props = defineProps(['isDialogOpen', 'document', 'directories']);
const emit = defineEmits(['closeDialog', 'documentUpdated']);
const toast = useToast()
const $t = useI18n().t;
const moveToDirectoryForm = ref();
const targetDirectoryId = ref();

const moveToDirectory = async () => {
  if(await moveToDirectoryForm.value.validate().valid) {
    return;
  }

  try {
    await axios.put(`/api/documents/${props.document.id}/directory`, {
      'directory_id': targetDirectoryId.value,
    });

    emit('documentUpdated');
    toast.success($t('notifications.document_moved'));
  } catch (error) {
    toast.error($t('errors.documents.move_error'));
  }

  emit('closeDialog')
  targetDirectoryId.value = null;
}
</script>

<template>
  <v-dialog v-model="props.isDialogOpen" max-width="500">
    <v-form @submit.prevent="moveToDirectory" ref="moveToDirectoryForm">
      <v-card :title="$t('documents.move_to_directory')">
        <v-card-text>
          <v-select
            v-model="targetDirectoryId"
            class="mt-3"
            :label="$t('common.directory')"
            outlined
            dense
            clearable
            required
            variant="underlined"
            :items="props.directories"
            item-value="id"
            item-title="name"
          ></v-select>
        </v-card-text>

        <v-card-actions>
          <v-btn
            :text="$t('common.cancel')"
            variant="tonal"
            @click="emit('closeDialog')"
          ></v-btn>

          <v-spacer></v-spacer>

          <v-btn
            class="btn"
            :disabled="!targetDirectoryId"
            type="submit"
            variant="tonal"
          >
            {{$t('common.move')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<style scoped lang="scss">
.btn {
  color: black !important;
  background-color: var(--booger-buster);
}

.btn:disabled {
  color: black !important;
  background-color: var(--light-grey);
}
</style>
