<script setup lang="ts">
import { Document } from "@/types/editor/Document";
import DocumentPreview from "@/components/documents/list/DocumentPreview.vue";

const { document } = defineProps<{
  document: Document;
}>();

const selectedDocumentId = ref<string | null>(null);

const handleShowDocument = (id: string) => {
  selectedDocumentId.value = id;
};
</script>

<template>
  <v-card @click="handleShowDocument(document.id)">
    <template v-slot:prepend>
      <img src="/src/assets/icons/document.svg" alt="Documents list" />
    </template>
    <v-card-title class="pt-0 pb-8">
      <span class="document-name">{{ document.name }}</span>
    </v-card-title>
  </v-card>
  <template v-if="selectedDocumentId">
    <DocumentPreview
      :documentId="selectedDocumentId"
      @close="selectedDocumentId = null"
    />
  </template>
</template>

<style scoped lang="scss">
.document-name {
  font-size: 0.875rem;
  font-weight: 500;
}

.document-info,
.document-links {
  font-size: 0.688rem;
  font-weight: 500;
}
</style>
