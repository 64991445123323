<script setup>
import {useRouter} from "vue-router";
import {useToast} from "vue-toastification";
import axios from "axios";
import store from "@/store/store";

const router = useRouter();
const toast = useToast();
const isSent = ref(false);
const isLoaded = ref(false);
const user = computed(() => store.state.auth.user);

onMounted(() => {
  if(user.value.hasEmailConfirmed || user.value.isTemporary) {
    router.push("/new-document");
  } else {
    isLoaded.value = true;
  }
});

const handleResendEmail = async () => {
  await axios.post("/api/user/resend-verification-email");
  toast.success("Email has been sent!");
  isSent.value = true;
};
</script>
<template>
  <div v-if="isLoaded" class="container-not-found">
    <div class="content">
      <h1>Verification required</h1>
      <h2>
        You need to verify your email address before you can continue.
      </h2>
      <p>A verification email has been sent to your email address {{ user.email }}. If you did not receive the email, please check your spam folder.</p>
      <p>If you need the email to be resent, click button below.</p>
        <VBtn :disabled="isSent" class="btn" @click="handleResendEmail" variant="flat">Resend email</VBtn>
    </div>
  </div>
</template>
<style scoped lang="scss">

.container-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  text-align: center;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 20px;
    position: relative;
    @media (max-width: 945px) {
      margin-left: 0;
    }
  }

  h1 {
    font-size: 28px;
    color: #6b889a;
  }

  h2 {
    font-size: 1.375rem;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #6b889a;
  }

  p {
    font-size: 1.125rem;
    color: #b2b4b9;
  }

  .link {
    font-weight: 600;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .btn {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    background-color: var(--booger-buster);
  }

  .btn:disabled {
    opacity: 1 !important;
    background-color: var(--ghost-white);
  }
}
</style>
