<script setup>
import {defineProps, ref} from "vue";
import axios from "axios";
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import { useIsMobile } from '@/utils/checkMobile';
import {validateEmail, validateName, validatePhone} from "@/utils/validation";
import ChangePasswordDialog from "@/components/dialogs/ChangePasswordDialog.vue";

const isMobile = useIsMobile();

const {userData} = defineProps(["userData"]);
const confirmDialog = ref(false)

const user = ref({
  name: "",
  phone: "",
  email: "",
})

const errors = ref({
  name: "",
  phone: "",
  email: "",
})

const emailHasChanged = computed(() => user.value.email !== userData.email)

const isValid = () => {
  errors.value.name = validateName(user.value.name);
  errors.value.email = validateEmail(user.value.email);
  errors.value.phone = validatePhone(user.value.phone);

  return Object.values(errors.value).every((error) => error === "");
};

const showConfirmDialog = () => {
  if (isValid()) {
    confirmDialog.value = true
  }
}

const $t = useI18n().t;

const saveAccountDetails = async () => {
  confirmDialog.value = false

  await axios.put('/api/user', {
    ...userData,
    ...user.value,
  }).then(() => {
    errors.value = {}
    useToast().success($t('notifications.default_update_success'))

    if (emailHasChanged.value) {
      useToast().warning($t('notifications.email_change', {email: user.value.email}))
    }
  }).catch((error) => {
    errors.value = error.response.data.errors
    useToast().error(error.response.data.message)
  })
}


onMounted(() => {
  user.value = {
    name: userData.name,
    phone: userData.phone,
    email: userData.email,
  }
})
</script>

<template>
  <VForm class="box" @submit.prevent="saveAccountDetails">
    <div>
      <h4 v-if="!isMobile" class="header">{{ $t('profile.contact_details') }}</h4>
      <VRow dense>
        <VCol cols="12">
          <label class="input-label" for="name">
            {{ $t('common.name') }}
          </label>
          <VTextField
            v-model="user.name"
            :error-messages="errors.name"
            id="name"
            density="compact"
            variant="outlined"
            base-color="#E1E1E1"
          />
        </VCol>
        <VCol cols="12">
          <label class="input-label" for="phone">
            {{$t('common.phone')}}
          </label>
          <VTextField id="phone" v-model="user.phone" :error-messages="errors.phone" placeholder="+1234567890"
                      density="compact" variant="outlined" base-color="#E1E1E1"/>
        </VCol>
        <VCol cols="12">
          <label class="input-label" for="email">
            {{$t('common.email')}}
          </label>
          <VTextField v-model="user.email" :error-messages="errors.email" id="email"
                      density="compact" type="email" variant="outlined" base-color="#E1E1E1"/>
        </VCol>
      </VRow>
    </div>
    <div class="button-container">
      <VBtn @click="showConfirmDialog"
            type="button"
            class="form-button"
            variant="flat"
            :disabled="user.password !== user.password_confirmation"> {{ $t('common.save') }}
      </VBtn>
    </div>
  </VForm>
    <VDialog
      v-model="confirmDialog"
      max-width="400"
      class="dialog"
    >
      <VCard>
        <div class="close-container">
          <img @click="confirmDialog = false" src="/images/icons/close.svg" alt="close dialog" />
        </div>
        <span class="dialog-title">
          {{ $t('common.confirm_changes') }}
        </span>
        <div class="dialog-actions-container">
          <VBtn variant="tonal" class="form-button" @click="saveAccountDetails">
            {{$t('common.confirm')}}
          </VBtn>
        </div>
      </VCard>
    </VDialog>
</template>
<style scoped lang="scss">
.header {
  margin-bottom: 30px;
}

.form-button {
  font-size: 1.125rem;
  color: white !important;
  background-color: var(--cambridge-blue);
  text-transform: none;
  border-radius: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 145px;
  height: 45px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
}

.button-container {
  display: flex;
  justify-content: end;

  @media (min-width: 768px) {
    gap: 20px;
  }
}
.dialog-actions-container {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
.dialog-title {
  display: flex;
  width: 100%;
  justify-content: center;
  font-weight: bold;
  color: var(--eerie-black);
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 1.5rem;
  text-align: center;
}
.close-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20px;
  img {
    cursor: pointer;
  }
}
.input-label {
  color: var(--eerie-black);

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
}

@media (max-width: 768px) {
  :deep(.v-overlay__content) {
    width: 100% !important;
    max-width: 100% !important;
  }
}
</style>
