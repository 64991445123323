<script setup>
import Form from "../../components/auth/Form";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import axios from "axios";
import { useI18n } from "vue-i18n";
import { showErrorMessage } from "../../utils/errorHandler";

const store = useStore();
const router = useRouter();
const $t = useI18n().t;

const errorMessage = ref("");
const clearForm = ref(false);
const apiUrl = import.meta.env.VITE_APP_API_BASE_URL;

const handleExternalProviderAuth = async (name) => {
  useToast().clear();

  try {
    window.location.href = apiUrl + "/login/" + name;
  } catch (error) {
    errorMessage.value = $t("notifications.default_error");
  }
};

const handleLoginByEmail = async (formData) => {
  store.commit("auth/logout");
  try {
    const response = await axios.post("api/login", formData);
    store.commit("auth/login", response.data.token);
    await router.push("/new-document");
    useToast().clear();
    useToast().success($t("notifications.login_success", {
      timeout: 10000
    }));
  } catch (error) {
    const errorType = showErrorMessage(error.response.data.error);
    const errorString = errorType
      ? $t(`errors.auth.${errorType}`)
      : error.response.data.error;
    useToast().clear();
    useToast().error(errorString, {
      timeout: 10000
    });
    errorMessage.value = errorString;
  }
};

const handleSubmitForm = (value) => {
  errorMessage.value = "";

  switch (value) {
    case "google":
      handleExternalProviderAuth("google");
      break;
    case "apple":
      handleExternalProviderAuth("apple");
      break;
    case "microsoft":
      handleExternalProviderAuth("microsoft");
      break;
    default:
      handleLoginByEmail(value);
  }
};
</script>

<template>
  <div class="container">
    <img
      class="logo"
      src="/src/assets/images/logo.svg"
      alt="Legal torch logo"
    />
    <div class="form-container">
      <Form
        variant="login"
        :title="$t('auth.signing_in')"
        :clearForm="clearForm"
        @submit="handleSubmitForm"
        :errorMessage="errorMessage"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100%;

  .form-container {
    width: 100%;
    display: flex;
    justify-content: center;
    background: transparent url("@/assets/backgrounds/auth.png") 40% 20%
      no-repeat padding-box;
    background-size: 30%;
    min-height: 620px;
    padding-bottom: 50px;
  }

  .logo {
    width: 256px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .form-container {
    width: 80% !important;
    background: none !important;
  }
  .container {
    background: transparent url("@/assets/backgrounds/auth.png") 0 50% no-repeat
      padding-box;
  }
}
</style>
