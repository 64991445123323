<script setup lang="ts">
import CategoryContent from "./CategoryContent.vue";

const { category, documents, isOpen } = defineProps([
  "category",
  "documents",
  "isOpen",
]);
const emit = defineEmits(["clickCategory", "clickDocument"]);

const handleSelectCategory = () => {
  emit("clickCategory", category.id);
};
const handleClickDocument = (documentId: string) => {
  emit("clickDocument", documentId);
};
</script>
<template>
  <li class="category">
    <div class="category__content" @click="handleSelectCategory">
      <span class="category__name">
        ({{ category?.children?.length }}) {{ category.name }}
      </span>

      <img
        src="/images/icons/arrow.svg"
        :class="{ category__arrow: true, rotate: isOpen }"
        alt="category__arrow"
      />
    </div>

    <CategoryContent
      v-if="documents"
      :open="isOpen"
      :documents="documents"
      @clickDocument="handleClickDocument"
    />
  </li>
</template>
<style scoped lang="scss">
.category {
  width: 320px;

  .category__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    padding: 10px;
    border: 1px solid var(--grey);
    overflow: hidden;
    transition: 0.2s ease-in-out;

    &:hover {
      cursor: pointer;
      background-color: var(--light-grey);
    }
  }

  .category__name {
    user-select: none;
    text-wrap: nowrap;
  }

  .category__arrow {
    width: 12px;
    transform: translateY(-50%) rotate(0deg);
    transition: 0.2s ease-in-out;
    user-select: none;

    &.rotate {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

@media (max-width: 768px) {
  .category {
    width: 100%;
  }
}
</style>
