<script setup>
import axios from "axios";
import { useStore } from "vuex";

const emit = defineEmits(["handle-template"]);
const { items } = defineProps(["items"]);
const templates = ref([]);
const store = useStore();

const getTemplatesList = async () => {
  const response = await axios.get("api/templates", {
    params: {
      page: 1,
      per_page: items,
      sort: "-documents_count",
    },
  });
  templates.value = response.data.data;
};

const handleTemplate = (templateId) => {
  store.commit("shared/updateIsLoading", true);
  emit("handle-template", templateId);
};

onMounted(() => {
  getTemplatesList();
});
</script>

<template>
  <div class="latest-templates-container">
    <div class="latest-templates-header">
      <h3>{{ $t("documents.popular_templates") }}</h3>
    </div>
    <div class="latest-templates">
      <div v-for="template in templates" :key="template.id">
        <v-card width="220" @click="handleTemplate(template.id)">
          <v-card-title>
            {{ template.name }}
          </v-card-title>
          <v-card-text>
            {{ template.documents_count }} {{ $t("common.documents") }}
          </v-card-text>
          <v-card-actions>
            <v-btn>{{ $t("documents.use_template") }}</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.latest-templates-container {
  display: flex;
  flex-direction: column;

  .latest-templates-header {
    width: 100%;
  }

  .latest-templates {
    display: flex;
    gap: 20px;
    padding-top: 10px;
  }
}
</style>
