<script setup>
import axios from "axios";
import { useToast } from "vue-toastification";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

const props = defineProps(["isDialogOpen", "document"]);
const emit = defineEmits(["closeDialog", "documentUpdated"]);
const toast = useToast();
const store = useStore();
const $t = useI18n().t;
const documentName = ref("");
const documentSaveAsForm = ref();

const saveDocumentAs = async () => {
  if (!(await documentSaveAsForm.value.validate()).valid) {
    return;
  }
  try {
    await axios.put(`/api/documents/${props.document.id}/name`, {
      name: documentName.value,
    });

    toast.success($t("notifications.document_saved"));
  } catch (error) {
    if (error.response.status === 402) {
      toast.error($t("documents.subscription_or_single_payment_required"));
    } else {
      toast.error($t("notifications.document_save_error"));
    }
  }

  emit("closeDialog");
  emit("documentUpdated");
};
onMounted(() => {
  documentName.value = props.document.name;
});
</script>

<template>
  <v-dialog v-model="props.isDialogOpen" max-width="500">
    <v-form @submit.prevent="saveDocumentAs" ref="documentSaveAsForm">
      <v-card :title="$t('common.save_as')">
        <v-card-text>
          <v-text-field
            v-model="documentName"
            :rules="[(v) => !!v || $t('errors.documents.name_required')]"
            class="mt-3"
            :label="$t('documents.name')"
            outlined
            dense
            clearable
            required
            variant="underlined"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-btn
            :text="$t('common.cancel')"
            variant="tonal"
            @click="emit('closeDialog')"
          ></v-btn>

          <v-spacer></v-spacer>

          <v-btn type="submit" color="primary" variant="flat">
            {{ $t("common.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
