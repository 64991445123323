<script setup>
import AlgoliaSearchMobile from "@/views/app/mobile/AlgoliaSearchMobile.vue";

const emit = defineEmits(["handle-template"]);

const handleTemplate = (templateId) => {
  emit("handle-template", templateId);
};
</script>

<template>
  <AlgoliaSearchMobile @handle-template="handleTemplate"/>
</template>

<style scoped lang="scss">
</style>
