import Notifications from "@/views/app/mobile/Notifications.vue";
import Menu from "@/views/app/mobile/Menu.vue";
import TemplateCategory from "@/views/app/mobile/TemplateCategory.vue";
import GeneralChat from "@/views/app/mobile/GeneralChat.vue";
import DocumentChat from "@/views/app/mobile/DocumentChat.vue";

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/app/LayoutApp.vue"), //do not change to static imports - threatens to be cataclysmic on production
    children: [
      {
        path: "/menu",
        name: "menu",
        component: Menu,
      },
      {
        path: "/notifications",
        name: "notifications",
        component: Notifications,
      },
      {
        path: "/general-chat",
        name: "general-chat",
        component: GeneralChat,
      },
      {
        path: "/documents/:documentId/chat",
        name: "document-chat",
        component: DocumentChat,
      },
      {
        path: "/document-category/:categoryId",
        name: "mobile-template-category",
        component: TemplateCategory,
      }
    ],
  },
];

export default routes;
