<script setup>
import axios from "axios";
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import {validatePassword} from "@/utils/validation";
import {ref} from "vue";

const props = defineProps(["isDialogOpen"]);
const emit = defineEmits(["closeDialog"]);
const toast = useToast();
const { t } = useI18n();

const oldPassword = ref("");
const newPassword = ref("");
const newPasswordConfirmation = ref("");
const errors = ref({
  password: "",
  password_confirmation: "",
})

const isPasswordValid = () => {
  errors.value.password = validatePassword(newPassword.value, newPassword.value, true);
  errors.value.password_confirmation = validatePassword(newPassword.value, newPasswordConfirmation.value, true);

  return errors.value.password === "" && errors.value.password_confirmation === "";
}

const changePassword = async () => {
  if(!isPasswordValid()) {
    return;
  }

  if (newPassword.value !== newPasswordConfirmation.value) {
    return toast.error(t("account.password_mismatch"));
  }

  try {
    await axios.put("/api/user/password", {
      current_password: oldPassword.value,
      password: newPassword.value,
      password_confirmation: newPasswordConfirmation.value,
    });
    emit("closeDialog");
    toast.success(t("account.password_changed"));
  } catch (error) {
    toast.error(error.response.data.error);
  }
};
</script>

<template>
  <v-dialog
    v-model="props.isDialogOpen"
    max-width="600"
  >
    <v-card>
      <div class="close-container">
        <img @click="emit('closeDialog')" src="/images/icons/close.svg" alt="close dialog"/>
      </div>
      <v-card-title class="dialog-title">
        {{ $t('account.change_password') }}
      </v-card-title>
      <v-card-text class="dialog-content">
        <label class="input-label" for="old-password">
          {{ $t('account.current_password') }}
        </label>
        <v-text-field
          id="old-password"
          class="mb-3"
          v-model="oldPassword"
          variant="outlined"
          density="compact"
          required
          type="password"
        />

        <label class="input-label" for="new-password">
          {{ $t('account.new_password') }}
        </label>
        <v-text-field
          :error-messages="errors.password"
          id="new-password"
          class="mb-3"
          v-model="newPassword"
          variant="outlined"
          density="compact"
          required
          type="password"
        />

        <label class="input-label" for="password-confirmation">
          {{ $t('account.confirm_new_password') }}
        </label>
        <v-text-field
          :error-messages="errors.password_confirmation"
          id="password-confirmation"
          class="mb-3"
          v-model="newPasswordConfirmation"
          variant="outlined"
          density="compact"
          required
          type="password"
        />
      </v-card-text>

      <v-divider class="mb-5"></v-divider>

      <v-card-actions>
        <v-btn
          :text="$t('common.save')"
          variant="flat"
          class="form-button"
          @click="changePassword"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.dialog-title {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 23px 23px 0px;
}

.dialog-content {
  color: #858788;;
}

.close-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20px;

  img {
    cursor: pointer;
  }
}

.input-label {
  color: var(--eerie-black);

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
}

.form-button {
  background-color: var(--cambridge-blue);
  color: white;
}
</style>
