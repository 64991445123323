const createComment = () => ({
  inline: true,
  group: "inline",
  atom: true,
  attrs: { comment: {} },
  parseDOM: [{ tag: "span.comment", getAttrs(dom) { return { comment: dom.getAttribute("comment") }; } }],
  toDOM(node) { return ["span", { class: "comment", comment: node.attrs.comment }, 0]; }
});

export default createComment;
