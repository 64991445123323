const parseStyle = (attrs) => {
  let style = "";
  const { align, lineHeight } = attrs;

  if (align && align !== "left") {
    style += `text-align: ${align};`;
  }
  if (lineHeight && lineHeight !== "100%") {
    style += `line-height: ${lineHeight}%;`;
  }

  return style.length > 0 ? style : undefined;
};

const parseClass = (attrs) => {
  const { hidden, class: className } = attrs;

  let newClassName = className || "";

  if (hidden) {
    const includes = newClassName.includes("hidden-paragraph");
    const addSpace = newClassName.length > 0 ? " " : "";
    !includes && (newClassName += `${addSpace}hidden-paragraph`);
  }
  return newClassName || null;
};

const createParagraph = (nodes) => ({
  ...nodes.get("paragraph"),
  toDOM(node) {
    const attrs = {};

    const isHidden = node.attrs.class?.includes("hidden-paragraph");

    const contentWrapper = isHidden
      ? ["span", generateLoremIpsum(node.content.size)]
      : 0;

    return [
      "p",
      {
        ...attrs,
        "data-id": node.attrs.id || crypto.randomUUID(),
        startNewPage: node.attrs.startNewPage || undefined,
        class: parseClass(node.attrs),
        style: parseStyle(node.attrs),
        contenteditable: node.attrs.contenteditable,
      },
      contentWrapper,
    ];
  },
  attrs: {
    align: { default: null },
    lineHeight: { default: null },
    startNewPage: { default: null },
    id: { default: crypto.randomUUID() },
    class: { default: null },
    contenteditable: { default: null },
  },
});
export default createParagraph;

function generateLoremIpsum(length) {
  const string =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent vel ante ligula. Nullam id rhoncus ante. Fusce tempor libero tellus, eu ullamcorper nunc cursus eget. Cras tempor vehicula massa at venenatis. Nullam laoreet hendrerit velit malesuada pharetra. Cras sit amet elit nisi. Quisque consequat condimentum leo, sit amet volutpat sapien. Vestibulum tellus est, vehicula vitae tempor id, elementum elementum elit. Vestibulum sed velit nec dolor mollis mattis ac vel ipsum. In id dignissim urna. Fusce ultricies ultricies arcu, a pulvinar enim tincidunt a. Nullam et maximus est, vitae dignissim ante. Suspendisse eu lorem eu urna rhoncus hendrerit. Etiam tristique arcu at est sollicitudin, vel dictum lectus aliquet. Nulla sed finibus ex, non tincidunt velit.";
  return string.slice(0, length);
}
