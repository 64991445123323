<script setup>
import { useI18n } from "vue-i18n";

const {
  tabs,
  activeTab,
  disabled = false,
} = defineProps(["tabs", "activeTab", "disabled"]);
const emit = defineEmits(["changeTab"]);
const { t } = useI18n();

const handleClickTab = (tab) => {
  const newTab = tab === activeTab ? null : tab;
  emit("changeTab", newTab);
};
</script>
<template>
  <div class="tabs">
    <div
      v-for="tab in tabs"
      :class="`tab ${activeTab === tab.name ? 'active' : ''} ${
        disabled ? 'disabled' : ''
      }`"
      @click="() => (!disabled ? handleClickTab(tab.name) : null)"
    >
      <div
        class="tab__icon"
        :style="`mask: url(/images/icons/editor-toolbar/${tab.icon}.svg) no-repeat center`"
      ></div>
      <p>
        {{ t(`creator.toolbar.${tab.name.toLowerCase()}`).toUpperCase() }}
      </p>
    </div>
  </div>
</template>
<style scoped lang="scss">
.tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .disabled {
    opacity: 0.7;
    cursor: not-allowed !important;
  }

  .tab {
    font-size: 0.875rem;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
    width: 25%;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: 0.2s ease;
    &:hover {
      cursor: pointer;
      background-color: var(--light-grey);
      .tab__icon {
        background-color: var(--blue-bonnet);
      }
    }

    .tab__icon {
      width: 30px;
      height: 30px;
      background-color: var(--grey);
      transition: 0.3s ease;
    }

    &.active {
      .tab__icon {
        background-color: var(--blue-bonnet);
      }
    }
  }
}
</style>
