<script setup lang="ts">
import { useStore } from "vuex";
import { Question } from "@/types/editor/Wizard";

const { question } = defineProps<{
  question: Question;
}>();

const store = useStore();

const answers = store.state.editor.wizard.answers;
const answer = ref(
  answers.find((a) => a.questionId === question.id)?.answer || null
);

question.options = Object.keys(question.options).map((key) => {
  return {
    value: question.options[key]["@attributes"].value,
    label: question.options[key]["@attributes"].label,
  };
});

const handleSelect = () => {
  store.commit("editor/updateWizardAnswer", {
    questionId: question.id,
    answer: answer.value,
  });
};
</script>

<template>
  <div class="wizard-question-container">
    <div class="wizard-question-content">
      <p>
        {{ question.content }}
      </p>
      <v-select
        v-model="answer"
        @update:modelValue="handleSelect"
        :items="question.options"
        item-value="value"
        item-title="label"
        variant="outlined"
        class="mt-2"
      ></v-select>
    </div>
  </div>
</template>

<style scoped lang="scss">
select {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background-color: antiquewhite;
}

:deep(.v-select) {
  width: calc(100% - 1px)
}
</style>
