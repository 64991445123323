<script setup>

import { useI18n } from "vue-i18n";

const {isPremium} = defineProps(["isPremium"]);

const t = useI18n().t;

const freeFeatures = [
  {
    title: t("billing.free_subscription_details"),
    description: t("billing.free_subscription_details_info"),
  },
  {
    title: t("billing.free_subscription_ai_assistant"),
    description: t("billing.free_subscription_ai_assistant_info")
  },
  {
    title: t("billing.free_subscription_dashboard"),
    description: t("billing.free_subscription_dashboard_details")
  },
];

const premiumfeatures = [
  {
    title: t("billing.premium_subscription_details"),
    description: t("billing.premium_subscription_details_info"),
  },
  {
    title: t("billing.premium_subscription_ai_assistant")
  },
  {
    title: t("billing.premium_subscription_dashboard")
  },
]

const info = computed(() => {
  return isPremium ? t("billing.premium_subscription") : t("billing.free_subscription");
});

const headerTitle = computed(() => {
  return isPremium ? t("billing.premium_plan") : t("billing.free_plan");
});

const features = computed(() => {
  return isPremium ? premiumfeatures : freeFeatures;
});
</script>
<template>
  <div class="info-wrapper">
    <div class="container">
      <div class="icon">i</div>

      <div class="content">
        <p>{{ info }}</p>
      </div>
    </div>

    <div class="features">
      <h4 class="features__title">{{ headerTitle }}</h4>
      <div class="feature" v-for="{ title, description } in features" :key="title">
        <div class="icon">i</div>
        <div class="wrapper">
          <p class="title">{{ title }}</p>
          <p class="description">
            {{ description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.container {
  position: relative;
  border: 1px solid var(--grey);
  padding: 15px 30px;
  padding-left: calc(30px + 12px * 2);
  margin-top: 50px;
}
.content {
  font-size: 0.875rem;
  font-weight: 600;
}
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: var(--blue-bonnet);
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  font-weight: 600;
  position: absolute;
  left: 12px;
  top: 7px;
}
.features {
  margin-top: 40px;

  &__title {
    font-size: 0.875rem;
    margin-bottom: 20px;
  }
}
.feature {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
  .icon {
    position: static;
    margin-top: 4px;
  }
  .description {
    font-size: 0.688rem;
  }
}
</style>
