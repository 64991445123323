<script setup>
import Form from "../../components/auth/Form";
import {useStore} from "vuex";
import {useToast} from "vue-toastification";
import {useRouter} from "vue-router";
import axios from "axios";
import {useI18n} from "vue-i18n";

const store = useStore();
const router = useRouter();
const $t = useI18n().t;

const errorMessage = ref("");
const clearForm = ref(false);
const apiUrl = import.meta.env.VITE_APP_API_BASE_URL;

const handleExternalProviderAuth = async (name) => {
  try {
    store.commit("auth/logout");
    window.location.href = apiUrl + '/login/' + name;
  } catch (error) {
    errorMessage.value = $t('notifications.login_error');
  }
};
const handleEmail = async (formData) => {
  try {
    const response = await axios.post("api/register", formData);
    if (response.status === 201) {
      useToast().success($t('notifications.register_success'));
      await login(response.data.token)
    }
  } catch (error) {
    if (error.response.status === 422) {
      useToast().error($t('notifications.auth_error_account_exists'));
    } else {
      useToast().error($t('notifications.default_error'));
    }
    errorMessage.value = error.response.data.message;
  }

};

const login = async (token) => {
  store.commit("auth/logout");
  store.commit("auth/login", token);
  await router.push("/documents");
}

const handleSubmitForm = (value) => {
  switch (value) {
    case "google":
      handleExternalProviderAuth("google");
      break;
    case "apple":
      handleExternalProviderAuth("apple");
      break;
    case "microsoft":
      handleExternalProviderAuth("microsoft");
      break;
    default:
      handleEmail(value);
  }
};
</script>

<template>
  <div class="container">
    <img class="logo" src="/src/assets/images/logo.svg" alt="Legal torch logo"/>
    <div class="form-container">
      <Form
        variant="register"
        :title="$t('auth.signing_up')"
        :clearForm="clearForm"
        @submit="handleSubmitForm"
        :errorMessage="errorMessage"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100%;

  .form-container {
    width: 100%;
    display: flex;
    justify-content: center;
    background: transparent url('@/assets/backgrounds/auth.png') 40% 20% no-repeat padding-box;
    background-size: 35%;
    min-height: 620px;
    padding-bottom: 20px;
  }

  .logo {
    width: 256px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .form-container {
    width: 80% !important;
    background: none !important;
  }
  .container {
    background: transparent url("@/assets/backgrounds/auth.png") 0 47%
    no-repeat padding-box;
  }
}
</style>
