<script setup>
import axios from "axios";
import router from "@/router";
import store from "@/store/store";
import {useI18n} from "vue-i18n";
import SubscriptionOrSinglePaymentRequiredDialog
  from "@/components/dialogs/SubscriptionOrSinglePaymentRequiredDialog.vue";
import TemplatesListMobile from "@/views/app/mobile/TemplatesListMobile.vue";
import LatestDocumentsMobileList from "@/views/app/mobile/LatestDocumentsMobileList.vue";
import PopularTemplatesMobileList from "@/views/app/mobile/PopularTemplatesMobileList.vue";
import Page from "@/components/Page.vue";
import LegalAssistant from "@/components/mobile/LegalAssistant.vue";

const $t = useI18n().t;
const selectedTemplateId = ref();
const isPaymentDialogOpen = ref(false);

const handleTemplate = async (templateId) => {
  try {
    const response = await axios.post("api/documents", {
      template_id: templateId,
    });

    store.commit("editor/updateDocumentId", response.data.data.id);
    store.commit("chat/updateSessionId", response.data.data.id);

    await router.push({
      name: "new-document-creator",
      params: {uuid: response.data.data.id},
    });
  } catch (error) {
    if (error.response.status === 402) {
      selectedTemplateId.value = templateId;
      isPaymentDialogOpen.value = true;
    }
  }
};
</script>

<template>
  <Page :title="$t('documents.new_document')" :with-back-button="false">
  <div class="new-document-container">
    <div class="documents-container">
      <TemplatesListMobile @handle-template="handleTemplate"/>
      <div class="spacer mt-5 mb-5"></div>
      <LatestDocumentsMobileList :items="3"/>
      <div class="spacer mt-5 mb-5"></div>
      <PopularTemplatesMobileList @handle-template="handleTemplate" :items="3"/>
    </div>
  </div>
  <SubscriptionOrSinglePaymentRequiredDialog
    v-if="selectedTemplateId"
    :template-id="selectedTemplateId"
    :is-dialog-open="isPaymentDialogOpen"
    @close-dialog="isPaymentDialogOpen = false"
  />
    <template #footer>
      <LegalAssistant route="/general-chat" />
    </template>
  </Page>
</template>

<style scoped lang="scss">
.new-document {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;

  .new-document-header {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
    width: 100%;
    font-weight: bold;
    font-size: 1.5rem;
    border-image: linear-gradient(
        to left,
        var(--cambridge-blue),
        var(--booger-buster)
    ) 30;
    border-width: 2px;
    border-style: solid;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    padding-bottom: 15px;
  }

  .new-document-container {
    display: flex;
    margin-top: 20px;
    height: 100%;

    .documents-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}
</style>
