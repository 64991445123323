import axios from "axios";
import store from "@/store/store";
import { applyStyleToParagraph } from "@/utils/editorStyling";
import scrollToElement from "@/utils/scrollToElement";
import pause from "@/utils/pause";

export default async function handleChangeDocument(paragraph_id, newDocument) {
  store.commit("editor/setRestoreVersion", mockNewDocument);
  applyStyleToParagraph(paragraph_id, "info");

  const paragraphAsNode = document.querySelector(
    `[data-id*="${paragraph_id}"]`
  );
  pause(1000);

  scrollToElement(paragraphAsNode);
}

const mockNewDocument = {
  type: "doc",
  content: [
    {
      type: "container",
      content: [
        {
          type: "paragraph",
          attrs: {
            startNewPage: false,
            id: null,
            class: "header",
            contenteditable: true,
          },
          content: [
            {
              type: "text",
              marks: [{ type: "strong" }],
              text: "SHARE TRANSFER AGREEMENT",
            },
          ],
        },
        {
          type: "paragraph",
          attrs: {
            startNewPage: false,
            id: "081219d8-cdef-4251-8af8-e6d28277a085",
            class: null,
            contenteditable: true,
          },
          content: [
            {
              type: "text",
              text: "This share transfer agreement (the “Agreement”) is made by and between",
            },
          ],
        },
        {
          type: "paragraph",
          attrs: {
            startNewPage: false,
            id: "5fc16154-98e8-4159-85eb-40d8203e2938",
            class: null,
            contenteditable: true,
          },
          content: [
            {
              type: "text",
              marks: [{ type: "strong" }],
              text: "\n            Of the first part",
            },
          ],
        },
        {
          type: "container",
          content: [
            {
              type: "paragraph",
              attrs: {
                startNewPage: false,
                id: "e7e96238-0b81-402e-b268-316c78d42839",
                class: null,
                contenteditable: true,
              },
              content: [
                {
                  type: "variable",
                  attrs: {
                    name: "Name of the company",
                    description: "Transferor's company name",
                    value: "dasd",
                    id: "1",
                    answer: "dasd",
                  },
                },
                {
                  type: "text",
                  text: ", a company incorporated in Malta, bearing company registration number",
                },
                {
                  type: "variable",
                  attrs: {
                    name: "Registration number of the company",
                    description: "Transferor's company registration number",
                    value: "dad322",
                    id: "2",
                    answer: "dad322",
                  },
                },
                {
                  type: "text",
                  text: "and having its registered office situated at",
                },
                {
                  type: "variable",
                  attrs: {
                    name: "Registration address of the company",
                    description: "Transferor's company registration address",
                    value: "fdfsf",
                    id: "3",
                    answer: "fdfsf",
                  },
                },
                {
                  type: "text",
                  text: "(hereinafter referred to as the “Transferor”);",
                },
              ],
            },
          ],
        },
        {
          type: "paragraph",
          attrs: {
            startNewPage: false,
            id: "0294d451-fd2d-428a-964a-902bce7ea637",
            class: null,
            contenteditable: true,
          },
          content: [
            {
              type: "text",
              marks: [{ type: "strong" }],
              text: "Of the second part",
            },
          ],
        },
        {
          type: "container",
          content: [
            {
              type: "paragraph",
              attrs: {
                startNewPage: false,
                id: "41abd5de-1945-4154-88b8-a073e908e7a5",
                class: null,
                contenteditable: true,
              },
              content: [
                {
                  type: "variable",
                  attrs: {
                    name: "Name of individual",
                    description: "Transferee's name",
                    value: "23432",
                    id: "12",
                    answer: "23432",
                  },
                },
                { type: "text", text: ", holder of" },
                {
                  type: "variable",
                  attrs: {
                    name: "Individual's nationality",
                    description: "Individual's transferee nationality",
                    value: "fsdfs",
                    id: "13",
                    answer: "fsdfs",
                  },
                },
                {
                  type: "variable",
                  attrs: {
                    name: "Individual's identity card",
                    description:
                      "Individual's transferee identity card type (passport etc.)",
                    value: "3432423",
                    id: "14",
                    answer: "3432423",
                  },
                },
                { type: "text", text: "number" },
                {
                  type: "variable",
                  attrs: {
                    name: "Individual's identity card",
                    description: "Individual's transferee identity card number",
                    value: "sdfsf",
                    id: "15",
                    answer: "sdfsf",
                  },
                },
                { type: "text", text: "and residing at" },
                {
                  type: "variable",
                  attrs: {
                    name: "Individual's residential address",
                    description: "Individual's transferee residential address",
                    value: "3432423",
                    id: "16",
                    answer: "3432423",
                  },
                },
                {
                  type: "text",
                  text: "(hereinafter referred to as the “Transferee”);",
                },
              ],
            },
          ],
        },
        {
          type: "container",
          content: [
            {
              type: "paragraph",
              attrs: {
                startNewPage: false,
                id: "9a5c6b11-82a0-46c7-8ed7-6f17ac89b8d4",
                class: null,
                contenteditable: true,
              },
              content: [
                {
                  type: "text",
                  marks: [{ type: "strong" }],
                  text: "Of the third party",
                },
              ],
            },
            {
              type: "container",
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    startNewPage: false,
                    id: "4955c319-04bc-40bf-aa8d-18dea0de4d15",
                    class: null,
                    contenteditable: true,
                  },
                  content: [
                    {
                      type: "variable",
                      attrs: {
                        name: "Name of individual",
                        description: "Pledgee's name",
                        value: "24423432",
                        id: "20",
                        answer: "24423432",
                      },
                    },
                    { type: "text", text: ", holder of" },
                    {
                      type: "variable",
                      attrs: {
                        name: "Pledgee's nationality",
                        description: "Pledgee's nationality",
                        value: "fsdfsd",
                        id: "21",
                        answer: "fsdfsd",
                      },
                    },
                    {
                      type: "variable",
                      attrs: {
                        name: "Pledgee's identity card type",
                        description:
                          "Pledgee's identity card type (passport etc.)",
                        value: "3432vsfd",
                        id: "22",
                        answer: "3432vsfd",
                      },
                    },
                    { type: "text", text: "number" },
                    {
                      type: "variable",
                      attrs: {
                        name: "Pledgee's identity card",
                        description: "Pledgee's identity card number",
                        value: "sfsfsd",
                        id: "23",
                        answer: "sfsfsd",
                      },
                    },
                    { type: "text", text: "and residing at" },
                    {
                      type: "variable",
                      attrs: {
                        name: "Pledgee's residential address",
                        description: "Pledgee's residential address",
                        value: "fdsfs34543",
                        id: "24",
                        answer: "fdsfs34543",
                      },
                    },
                    {
                      type: "text",
                      text: "(hereinafter referred to as the “Pledgee”);",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "paragraph",
          attrs: {
            startNewPage: false,
            id: "959df694-f7aa-4856-815d-7a151a582fb0",
            class: null,
            contenteditable: true,
          },
          content: [
            {
              type: "text",
              text: "(hereinafter the Transferor and the Transferee are collectively referred to as the “Parties” or each individually as a “Party”)",
            },
          ],
        },
        {
          type: "paragraph",
          attrs: {
            startNewPage: false,
            id: "a3aa9e4b-20e9-4b57-8343-00145b9e4937",
            class: null,
            contenteditable: true,
          },
          content: [
            { type: "text", marks: [{ type: "strong" }], text: "WHEREAS:" },
          ],
        },
        {
          type: "ordered_list",
          attrs: { order: "1", type: null, class: null },
          content: [
            {
              type: "list_item",
              content: [
                {
                  type: "container",
                  content: [
                    {
                      type: "paragraph",
                      attrs: {
                        startNewPage: false,
                        id: "5e8bec30-f034-422d-b5b4-5b19afcaa363",
                        class: null,
                        contenteditable: true,
                      },
                      content: [
                        {
                          type: "text",
                          text: "the Transferor is the holder of the Shares;",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: "list_item",
              content: [
                {
                  type: "container",
                  content: [
                    {
                      type: "paragraph",
                      attrs: {
                        startNewPage: false,
                        id: "8aa60545-c763-4531-8a66-9031bb43bf11",
                        class: null,
                        contenteditable: true,
                      },
                      content: [
                        {
                          type: "text",
                          text: "the Transferor wishes to transfer, assign and sell the Shares to the Transferee who wishes to accept, acquire and buy the Shares.",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "paragraph",
          attrs: {
            startNewPage: true,
            id: "0a466112-c302-4803-88c7-f1d831e74067",
            class: null,
            contenteditable: true,
          },
          content: [
            {
              type: "text",
              marks: [{ type: "strong" }],
              text: "\n            NOW THEREFORE,",
            },
            { type: "text", text: "the Parties hereby agree as follows:" },
          ],
        },
        {
          type: "ordered_list",
          attrs: { order: "1", type: null, class: null },
          content: [
            {
              type: "list_item",
              content: [
                {
                  type: "container",
                  content: [
                    {
                      type: "paragraph",
                      attrs: {
                        startNewPage: false,
                        id: "617d97e9-036b-4e0e-a436-f7f1136ea067",
                        class: null,
                        contenteditable: true,
                      },
                      content: [
                        {
                          type: "text",
                          marks: [{ type: "underline" }],
                          text: "Definitions and Interpretation",
                        },
                      ],
                    },
                    {
                      type: "ordered_list",
                      attrs: { order: "1", type: null, class: null },
                      content: [
                        {
                          type: "list_item",
                          content: [
                            {
                              type: "container",
                              content: [
                                {
                                  type: "paragraph",
                                  attrs: {
                                    startNewPage: false,
                                    id: "f7adee96-998e-4be5-b270-b3a9a6555709",
                                    class: null,
                                    contenteditable: true,
                                  },
                                  content: [
                                    {
                                      type: "text",
                                      text: "In this Agreement, unless the context otherwise requires, the following words shall have the following meanings:",
                                    },
                                  ],
                                },
                                {
                                  type: "ordered_list",
                                  attrs: {
                                    order: "1",
                                    type: null,
                                    class: null,
                                  },
                                  content: [
                                    {
                                      type: "list_item",
                                      content: [
                                        {
                                          type: "container",
                                          content: [
                                            {
                                              type: "paragraph",
                                              attrs: {
                                                startNewPage: false,
                                                id: "82d007a1-47c2-44e8-8a3b-0b7c3412b2ab",
                                                class: null,
                                                contenteditable: true,
                                              },
                                              content: [
                                                { type: "text", text: "means" },
                                                {
                                                  type: "variable",
                                                  attrs: {
                                                    name: "Name of the company",
                                                    description:
                                                      "Pledgee's company name",
                                                    value: "fdsfsfsd",
                                                    id: "17",
                                                    answer: "fdsfsfsd",
                                                  },
                                                },
                                                {
                                                  type: "text",
                                                  text: ", a company registered under the laws of",
                                                },
                                                {
                                                  type: "variable",
                                                  attrs: {
                                                    name: "Country of the company",
                                                    description:
                                                      "Country in which the company is registered",
                                                    value: "fsd2342",
                                                    id: "30",
                                                    answer: "fsd2342",
                                                  },
                                                },
                                                {
                                                  type: "text",
                                                  text: ", bearing company registration number",
                                                },
                                                {
                                                  type: "variable",
                                                  attrs: {
                                                    name: "Registration number of the company",
                                                    description:
                                                      "Pledgee's company registration number",
                                                    value: "24324",
                                                    id: "18",
                                                    answer: "24324",
                                                  },
                                                },
                                                {
                                                  type: "text",
                                                  text: "and having its registered office situated at",
                                                },
                                                {
                                                  type: "variable",
                                                  attrs: {
                                                    name: "Registration address of the company",
                                                    description:
                                                      "Pledgee's company registration address",
                                                    value: "fsdfsd",
                                                    id: "19",
                                                    answer: "fsdfsd",
                                                  },
                                                },
                                                { type: "text", text: ";" },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    {
                                      type: "list_item",
                                      content: [
                                        {
                                          type: "container",
                                          content: [
                                            {
                                              type: "paragraph",
                                              attrs: {
                                                startNewPage: false,
                                                id: "1e767ee7-e3e0-4113-9569-452fb990adce",
                                                class: null,
                                                contenteditable: true,
                                              },
                                              content: [
                                                {
                                                  type: "text",
                                                  text: "means in relation to the Transferor, a spouse, partner, all descendants and ascendants in the direct line and their relative spouses, or brothers and sisters and anyone related by consanguinity in the direct line or up to the degree of cousin in the collateral line and anyone related by affinity;",
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    {
                                      type: "list_item",
                                      content: [
                                        {
                                          type: "container",
                                          content: [
                                            {
                                              type: "paragraph",
                                              attrs: {
                                                startNewPage: false,
                                                id: "de97c2eb-3e11-47ab-83a1-b1630e6d731a",
                                                class: null,
                                                contenteditable: true,
                                              },
                                              content: [
                                                { type: "text", text: "means" },
                                                {
                                                  type: "variable",
                                                  attrs: {
                                                    name: "Amount of shares",
                                                    description:
                                                      "Amount of shares",
                                                    value: "fsdfs2",
                                                    id: "31",
                                                    answer: "fsdfs2",
                                                  },
                                                },
                                                {
                                                  type: "variable",
                                                  attrs: {
                                                    name: "Type of shares",
                                                    description:
                                                      "Type of shares",
                                                    value: "2423fs",
                                                    id: "32",
                                                    answer: "2423fs",
                                                  },
                                                },
                                                {
                                                  type: "text",
                                                  text: "shares of",
                                                },
                                                {
                                                  type: "variable",
                                                  attrs: {
                                                    name: "Currency of shares",
                                                    description:
                                                      "Currency and nominal value of shares",
                                                    value: "2342fsdfsd",
                                                    id: "33",
                                                    answer: "2342fsdfsd",
                                                  },
                                                },
                                                { type: "text", text: "each," },
                                                {
                                                  type: "variable",
                                                  attrs: {
                                                    name: "Percentage of the shares",
                                                    description:
                                                      "Enter percentage paid up of the shares in words and digits",
                                                    value: "23423fsdfsd",
                                                    id: "34",
                                                    answer: "23423fsdfsd",
                                                  },
                                                },
                                                {
                                                  type: "text",
                                                  text: "paid up in the Company.",
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "list_item",
                          content: [
                            {
                              type: "container",
                              content: [
                                {
                                  type: "paragraph",
                                  attrs: {
                                    startNewPage: false,
                                    id: "c6894a1a-55b9-4bcf-8ccb-448f323df964",
                                    class: null,
                                    contenteditable: true,
                                  },
                                  content: [
                                    {
                                      type: "text",
                                      text: "In this Agreement, except where the context otherwise requires:",
                                    },
                                  ],
                                },
                                {
                                  type: "ordered_list",
                                  attrs: {
                                    order: "1",
                                    type: null,
                                    class: null,
                                  },
                                  content: [
                                    {
                                      type: "list_item",
                                      content: [
                                        {
                                          type: "container",
                                          content: [
                                            {
                                              type: "paragraph",
                                              attrs: {
                                                startNewPage: false,
                                                id: "85977166-2fef-4c87-923e-316d608c5e08",
                                                class: null,
                                                contenteditable: true,
                                              },
                                              content: [
                                                {
                                                  type: "text",
                                                  text: "headings are included for convenience only and do not affect the interpretation of this Agreement;",
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    {
                                      type: "list_item",
                                      content: [
                                        {
                                          type: "container",
                                          content: [
                                            {
                                              type: "paragraph",
                                              attrs: {
                                                startNewPage: false,
                                                id: "c5ba52dc-0996-47b1-a54e-d10f19055899",
                                                class: null,
                                                contenteditable: true,
                                              },
                                              content: [
                                                {
                                                  type: "text",
                                                  text: "use of the singular includes the plural and vice versa;",
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    {
                                      type: "list_item",
                                      content: [
                                        {
                                          type: "container",
                                          content: [
                                            {
                                              type: "paragraph",
                                              attrs: {
                                                startNewPage: false,
                                                id: "f0ee4b46-f864-4c5b-884a-2219346d8186",
                                                class: null,
                                                contenteditable: true,
                                              },
                                              content: [
                                                {
                                                  type: "text",
                                                  text: "use of any gender includes the other genders;",
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    {
                                      type: "list_item",
                                      content: [
                                        {
                                          type: "container",
                                          content: [
                                            {
                                              type: "paragraph",
                                              attrs: {
                                                startNewPage: false,
                                                id: "2b10bbd7-e60d-42cc-aeb6-ba8dcd4831e7",
                                                class: null,
                                                contenteditable: true,
                                              },
                                              content: [
                                                {
                                                  type: "text",
                                                  text: "any reference to a person includes natural persons, firms, partnerships, companies, corporations, associations, organisations, governments, states, foundations or trusts;",
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    {
                                      type: "list_item",
                                      content: [
                                        {
                                          type: "container",
                                          content: [
                                            {
                                              type: "paragraph",
                                              attrs: {
                                                startNewPage: false,
                                                id: "3248a3da-4d34-4ace-8204-4b57d0d29f8d",
                                                class: null,
                                                contenteditable: true,
                                              },
                                              content: [
                                                {
                                                  type: "text",
                                                  text: "any reference to a person includes that person’s legal personal representatives, successors and assignees;",
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    {
                                      type: "list_item",
                                      content: [
                                        {
                                          type: "container",
                                          content: [
                                            {
                                              type: "paragraph",
                                              attrs: {
                                                startNewPage: false,
                                                id: "4ea4facf-3022-4f88-a4ad-da26518fc8de",
                                                class: null,
                                                contenteditable: true,
                                              },
                                              content: [
                                                {
                                                  type: "text",
                                                  text: "any reference to a ‘Clause’ or ‘Clauses’ shall be construed to refer to a clause or clauses of this Agreement; and",
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    {
                                      type: "list_item",
                                      content: [
                                        {
                                          type: "container",
                                          content: [
                                            {
                                              type: "paragraph",
                                              attrs: {
                                                startNewPage: false,
                                                id: "2fcfe0ca-8146-434e-9089-2633b1784cbf",
                                                class: null,
                                                contenteditable: true,
                                              },
                                              content: [
                                                {
                                                  type: "text",
                                                  text: "any phrase introduced by the terms “including”, “include”, “in particular” or any similar expression is illustrative only and does not limit the sense of the words preceding those terms.",
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: "list_item",
              content: [
                {
                  type: "container",
                  content: [
                    {
                      type: "paragraph",
                      attrs: {
                        startNewPage: false,
                        id: "1f17c756-8b37-47ae-8491-c2af7b89caf2",
                        class: null,
                        contenteditable: true,
                      },
                      content: [
                        {
                          type: "text",
                          marks: [{ type: "underline" }],
                          text: "TRANSFER",
                        },
                      ],
                    },
                    {
                      type: "ordered_list",
                      attrs: { order: "1", type: null, class: null },
                      content: [
                        {
                          type: "list_item",
                          content: [
                            {
                              type: "container",
                              content: [
                                {
                                  type: "paragraph",
                                  attrs: {
                                    startNewPage: false,
                                    id: "f119b6ef-300a-4d35-9eeb-5ff367dde2fe",
                                    class: "hidden-paragraph",
                                    contenteditable: false,
                                  },
                                  content: [
                                    {
                                      type: "text",
                                      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "list_item",
                          content: [
                            {
                              type: "container",
                              content: [
                                {
                                  type: "paragraph",
                                  attrs: {
                                    startNewPage: false,
                                    id: "01316b3c-7e9f-40be-b8b1-1a2dea17d231",
                                    class: "hidden-paragraph",
                                    contenteditable: false,
                                  },
                                  content: [
                                    {
                                      type: "text",
                                      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: "list_item",
              content: [
                {
                  type: "container",
                  content: [
                    {
                      type: "paragraph",
                      attrs: {
                        startNewPage: false,
                        id: "47ce1062-71d9-472d-8e28-5a4f2f47b538",
                        class: null,
                        contenteditable: true,
                      },
                      content: [
                        {
                          type: "text",
                          marks: [{ type: "underline" }],
                          text: "Warranties",
                        },
                      ],
                    },
                    {
                      type: "ordered_list",
                      attrs: { order: "1", type: null, class: null },
                      content: [
                        {
                          type: "list_item",
                          content: [
                            {
                              type: "container",
                              content: [
                                {
                                  type: "paragraph",
                                  attrs: {
                                    startNewPage: false,
                                    id: "14d6cf70-1796-4ad2-8ce6-07c6169fd5ff",
                                    class: null,
                                    contenteditable: true,
                                  },
                                  content: [
                                    {
                                      type: "text",
                                      text: "The Transferor hereby represents and warrants that:",
                                    },
                                  ],
                                },
                                {
                                  type: "ordered_list",
                                  attrs: {
                                    order: "1",
                                    type: null,
                                    class: null,
                                  },
                                  content: [
                                    {
                                      type: "list_item",
                                      content: [
                                        {
                                          type: "container",
                                          content: [
                                            {
                                              type: "paragraph",
                                              attrs: {
                                                startNewPage: false,
                                                id: "f4efd401-a4d6-42fe-b803-93fb0d4cc62a",
                                                class: null,
                                                contenteditable: true,
                                              },
                                              content: [
                                                {
                                                  type: "text",
                                                  text: "the Transferor is the sole legal and beneficial owner of the entire interest in the Shares;",
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    {
                                      type: "list_item",
                                      content: [
                                        {
                                          type: "container",
                                          content: [
                                            {
                                              type: "paragraph",
                                              attrs: {
                                                startNewPage: false,
                                                id: "955d9a99-2082-48c1-a786-a6d455c628a5",
                                                class: null,
                                                contenteditable: true,
                                              },
                                              content: [
                                                {
                                                  type: "text",
                                                  text: "the execution, delivery and performance of this transfer will not violate any legal requirement or contractual obligation of the Transferor; and",
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    {
                                      type: "list_item",
                                      content: [
                                        {
                                          type: "container",
                                          content: [
                                            {
                                              type: "container",
                                              content: [
                                                {
                                                  type: "paragraph",
                                                  attrs: {
                                                    startNewPage: false,
                                                    id: "6c1506f2-9199-4b69-8e82-6d810610c113",
                                                    class: null,
                                                    contenteditable: true,
                                                  },
                                                  content: [
                                                    {
                                                      type: "text",
                                                      text: "the Shares being transferred are owned by the Transferor, free and clear of any claim, set off, lien, encumbrance, pledge, option, security interest or third-party claim and constitute a valid and subsisting interest in the Company.",
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: "list_item",
              content: [
                {
                  type: "container",
                  content: [
                    {
                      type: "paragraph",
                      attrs: {
                        startNewPage: false,
                        id: "2806757e-d5b0-48ba-947e-2acfb1791140",
                        class: null,
                        contenteditable: true,
                      },
                      content: [
                        {
                          type: "text",
                          marks: [{ type: "underline" }],
                          text: "Waiver of Claims",
                        },
                      ],
                    },
                    {
                      type: "ordered_list",
                      attrs: { order: "1", type: null, class: null },
                      content: [
                        {
                          type: "list_item",
                          content: [
                            {
                              type: "container",
                              content: [
                                {
                                  type: "paragraph",
                                  attrs: {
                                    startNewPage: false,
                                    id: "00896f2a-c447-44d0-bc7d-41d8bf908637",
                                    class: null,
                                    contenteditable: true,
                                  },
                                  content: [
                                    {
                                      type: "text",
                                      text: "The Transferor hereby confirms to the Transferee, with effect from the date of this Agreement, that other then what is explicitly disclosed in this Agreement neither [he/she] nor any Related Person has any claim of any kind against the Company and that there are no agreements or arrangements outstanding under which the Company has or could have any debt or other obligation to him/it or any of its Related Persons.",
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "list_item",
                          content: [
                            {
                              type: "container",
                              content: [
                                {
                                  type: "paragraph",
                                  attrs: {
                                    startNewPage: false,
                                    id: "abd12bdd-a5e4-44ec-bd92-ecc1c5d66704",
                                    class: null,
                                    contenteditable: true,
                                  },
                                  content: [
                                    {
                                      type: "text",
                                      text: "To the extent that any such claim exists, or the Company has or could have any such debt or other obligation, with effect from and as at the date of this Agreement, the Transferor:",
                                    },
                                  ],
                                },
                                {
                                  type: "ordered_list",
                                  attrs: {
                                    order: "1",
                                    type: null,
                                    class: null,
                                  },
                                  content: [
                                    {
                                      type: "list_item",
                                      content: [
                                        {
                                          type: "container",
                                          content: [
                                            {
                                              type: "paragraph",
                                              attrs: {
                                                startNewPage: false,
                                                id: "72fdddf1-3c30-4258-a1da-3b96fc6fc102",
                                                class: null,
                                                contenteditable: true,
                                              },
                                              content: [
                                                {
                                                  type: "text",
                                                  text: "hereby irrevocably and unconditionally waives and forever discharges that claim and any right in respect of that debt or other obligation owed to it and releases the Company from any liability whatsoever in respect of it; and",
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    {
                                      type: "list_item",
                                      content: [
                                        {
                                          type: "container",
                                          content: [
                                            {
                                              type: "paragraph",
                                              attrs: {
                                                startNewPage: false,
                                                id: "4b1fa0e5-9b1e-42a0-9eb0-453ba2f31da2",
                                                class: null,
                                                contenteditable: true,
                                              },
                                              content: [
                                                {
                                                  type: "text",
                                                  text: "shall procure that any such claim and any right in respect of any such debt or other obligation owed to any Related Person is unconditionally waived and forever discharged and that the Company is released from any liability whatsoever in respect of it.",
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: "list_item",
              content: [
                {
                  type: "container",
                  content: [
                    {
                      type: "paragraph",
                      attrs: {
                        startNewPage: false,
                        id: "1375e595-7639-4735-9112-396db3a7f0b6",
                        class: null,
                        contenteditable: true,
                      },
                      content: [
                        {
                          type: "text",
                          marks: [{ type: "underline" }],
                          text: "Concurrent Actions and Documents",
                        },
                      ],
                    },
                    {
                      type: "ordered_list",
                      attrs: { order: "1", type: null, class: null },
                      content: [
                        {
                          type: "list_item",
                          content: [
                            {
                              type: "container",
                              content: [
                                {
                                  type: "paragraph",
                                  attrs: {
                                    startNewPage: false,
                                    id: "39bcefe6-7ab7-485b-b68f-11dad412f02a",
                                    class: null,
                                    contenteditable: true,
                                  },
                                  content: [
                                    {
                                      type: "text",
                                      text: "The Transferor undertakes to complete and execute all necessary forms and documents required to give effect to this Agreement including the forms to be filed with the Commissioner for Revenue and the Malta Business Registry.",
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "list_item",
                          content: [
                            {
                              type: "container",
                              content: [
                                {
                                  type: "paragraph",
                                  attrs: {
                                    startNewPage: false,
                                    id: "3779f7ba-e048-43b1-b166-722010d9490d",
                                    class: null,
                                    contenteditable: true,
                                  },
                                  content: [
                                    {
                                      type: "text",
                                      text: "Tax due on this transfer shall be borne by the transferor as regards the duty on capital gains and by the transferee as regards the duty on documents and transfer inter vivos.",
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: "list_item",
              content: [
                {
                  type: "container",
                  content: [
                    {
                      type: "paragraph",
                      attrs: {
                        startNewPage: false,
                        id: "cbc5b770-6a8b-4a74-a8f6-857fecbe7329",
                        class: null,
                        contenteditable: true,
                      },
                      content: [
                        {
                          type: "text",
                          marks: [{ type: "underline" }],
                          text: "No Party deemed Drafter",
                        },
                      ],
                    },
                    {
                      type: "ordered_list",
                      attrs: { order: "1", type: null, class: null },
                      content: [
                        {
                          type: "list_item",
                          content: [
                            {
                              type: "container",
                              content: [
                                {
                                  type: "paragraph",
                                  attrs: {
                                    startNewPage: false,
                                    id: "dfa9f02b-ea04-41fe-a3a6-76555e185dbb",
                                    class: null,
                                    contenteditable: true,
                                  },
                                  content: [
                                    {
                                      type: "text",
                                      text: "The Parties agree that they have each materially and fully participated in the negotiation and drafting of this Agreement and, if this Agreement should ever be the subject of interpretation by a court, arbitration or tribunal, it shall not be construed or interpreted against either Party for the reason that it was drafted by only one Party.",
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: "list_item",
              content: [
                {
                  type: "container",
                  content: [
                    {
                      type: "paragraph",
                      attrs: {
                        startNewPage: false,
                        id: "38c2c3d9-8b28-4f98-9912-5f91132f1584",
                        class: null,
                        contenteditable: true,
                      },
                      content: [
                        {
                          type: "text",
                          marks: [{ type: "underline" }],
                          text: "Amendments",
                        },
                      ],
                    },
                    {
                      type: "ordered_list",
                      attrs: { order: "1", type: null, class: null },
                      content: [
                        {
                          type: "list_item",
                          content: [
                            {
                              type: "container",
                              content: [
                                {
                                  type: "paragraph",
                                  attrs: {
                                    startNewPage: false,
                                    id: "bde82649-763b-4815-aed5-703f4f1daa46",
                                    class: null,
                                    contenteditable: true,
                                  },
                                  content: [
                                    {
                                      type: "text",
                                      text: "No variation of or amendments to this Agreement will be valid unless made in writing and signed by or on behalf of each of the Parties.",
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: "list_item",
              content: [
                {
                  type: "container",
                  content: [
                    {
                      type: "paragraph",
                      attrs: {
                        startNewPage: false,
                        id: "16f3c4f2-61cb-4a1d-987d-f248af5243dc",
                        class: null,
                        contenteditable: true,
                      },
                      content: [
                        {
                          type: "text",
                          marks: [{ type: "underline" }],
                          text: "Waiver",
                        },
                      ],
                    },
                    {
                      type: "ordered_list",
                      attrs: { order: "1", type: null, class: null },
                      content: [
                        {
                          type: "list_item",
                          content: [
                            {
                              type: "container",
                              content: [
                                {
                                  type: "paragraph",
                                  attrs: {
                                    startNewPage: false,
                                    id: "3264ef7b-64cc-485f-a4bd-b94dc38ea00e",
                                    class: null,
                                    contenteditable: true,
                                  },
                                  content: [
                                    {
                                      type: "text",
                                      text: "Any waiver of any provision of this Agreement must be in writing and signed by or on behalf of each of the Parties.",
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "list_item",
                          content: [
                            {
                              type: "container",
                              content: [
                                {
                                  type: "paragraph",
                                  attrs: {
                                    startNewPage: false,
                                    id: "d2e9a34b-8379-403e-9429-84df1933ee02",
                                    class: null,
                                    contenteditable: true,
                                  },
                                  content: [
                                    {
                                      type: "text",
                                      text: "No failure or delay by any Party in exercising any right, power or privilege under this Agreement will operate as a waiver thereof nor will any single or partial exercise thereof preclude any other or further exercise thereof or the exercise of any other right, power or privilege.",
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "list_item",
                          content: [
                            {
                              type: "container",
                              content: [
                                {
                                  type: "paragraph",
                                  attrs: {
                                    startNewPage: false,
                                    id: "0bb03ab9-0e0a-4b79-91ba-aa09bca20052",
                                    class: null,
                                    contenteditable: true,
                                  },
                                  content: [
                                    {
                                      type: "text",
                                      text: "Any waiver by a Party of a breach of any provision of this Agreement will not be a waiver of any subsequent breach of the same or any other provision.",
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: "list_item",
              content: [
                {
                  type: "container",
                  content: [
                    {
                      type: "paragraph",
                      attrs: {
                        startNewPage: false,
                        id: "0f5c1746-b224-4b31-ad05-f7aecbd960ef",
                        class: null,
                        contenteditable: true,
                      },
                      content: [
                        {
                          type: "text",
                          marks: [{ type: "underline" }],
                          text: "Severability",
                        },
                      ],
                    },
                    {
                      type: "ordered_list",
                      attrs: { order: "1", type: null, class: null },
                      content: [
                        {
                          type: "list_item",
                          content: [
                            {
                              type: "container",
                              content: [
                                {
                                  type: "paragraph",
                                  attrs: {
                                    startNewPage: false,
                                    id: "62e8f620-dfb9-4505-a559-92ead139fc0e",
                                    class: null,
                                    contenteditable: true,
                                  },
                                  content: [
                                    {
                                      type: "text",
                                      text: "If any term of this Agreement or any application of any such term shall be invalid, illegal or unenforceable, the remainder of this Agreement and any other application of such term shall remain in full force and effect.",
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: "list_item",
              content: [
                {
                  type: "container",
                  content: [
                    {
                      type: "paragraph",
                      attrs: {
                        startNewPage: false,
                        id: "734ac83a-24e4-4326-b3fb-787ac48a525c",
                        class: null,
                        contenteditable: true,
                      },
                      content: [
                        {
                          type: "text",
                          marks: [{ type: "underline" }],
                          text: "Counterparts",
                        },
                      ],
                    },
                    {
                      type: "ordered_list",
                      attrs: { order: "1", type: null, class: null },
                      content: [
                        {
                          type: "list_item",
                          content: [
                            {
                              type: "container",
                              content: [
                                {
                                  type: "paragraph",
                                  attrs: {
                                    startNewPage: false,
                                    id: "a0e3751b-7ccf-4d0a-a087-6d23dc6102b4",
                                    class: null,
                                    contenteditable: true,
                                  },
                                  content: [
                                    {
                                      type: "text",
                                      text: "This Agreement may be executed simultaneously in one or more counterparts (whether electronically or otherwise), each of which shall be deemed an original, and all of which together shall constitute one and the same instrument. An electronic or digital signature shall be deemed an original signature for the purposes of executing this Agreement.",
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: "list_item",
              content: [
                {
                  type: "container",
                  content: [
                    {
                      type: "paragraph",
                      attrs: {
                        startNewPage: false,
                        id: "1e3d2f85-77d1-4476-acaa-f988fb06ae41",
                        class: null,
                        contenteditable: true,
                      },
                      content: [
                        {
                          type: "text",
                          marks: [{ type: "underline" }],
                          text: "Governing Law and Jurisdiction",
                        },
                      ],
                    },
                    {
                      type: "ordered_list",
                      attrs: { order: "1", type: null, class: null },
                      content: [
                        {
                          type: "list_item",
                          content: [
                            {
                              type: "container",
                              content: [
                                {
                                  type: "paragraph",
                                  attrs: {
                                    startNewPage: false,
                                    id: "34381eb2-0562-435a-a47f-f689b4b504f6",
                                    class: null,
                                    contenteditable: true,
                                  },
                                  content: [
                                    {
                                      type: "text",
                                      text: "This Agreement shall be governed by, construed, enforced and interpreted in accordance with the laws of Malta and each Party irrevocably agrees that [the courts of Malta shall have exclusive jurisdiction to hear and determine any suit, action or proceedings and to settle any disputes which may arise out of or in connection with this Agreement.",
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: "list_item",
              content: [
                {
                  type: "container",
                  content: [
                    {
                      type: "paragraph",
                      attrs: {
                        startNewPage: false,
                        id: "3eef03e9-43a2-4c9c-ab76-401eab3a9c7b",
                        class: null,
                        contenteditable: true,
                      },
                      content: [
                        {
                          type: "text",
                          marks: [{ type: "underline" }],
                          text: "MISCELLANEOUS",
                        },
                      ],
                    },
                    {
                      type: "ordered_list",
                      attrs: { order: "1", type: null, class: null },
                      content: [
                        {
                          type: "list_item",
                          content: [
                            {
                              type: "container",
                              content: [
                                {
                                  type: "paragraph",
                                  attrs: {
                                    startNewPage: false,
                                    id: "bfc9d034-faf9-47d4-ab05-508767576474",
                                    class: null,
                                    contenteditable: true,
                                  },
                                  content: [
                                    {
                                      type: "text",
                                      text: "This Agreement constitutes the entire agreement between the Parties and supersedes all prior and contemporaneous agreements, understandings, and negotiations, whether written or oral.",
                                    },
                                  ],
                                },
                                {
                                  type: "paragraph",
                                  attrs: {
                                    startNewPage: false,
                                    id: null,
                                    class: null,
                                    contenteditable: true,
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "paragraph",
          attrs: {
            startNewPage: false,
            id: "ac5b6423-2e74-4e9b-a432-6a43cc7f24be",
            class: null,
            contenteditable: true,
          },
          content: [{ type: "text", text: "Lorem ipsum:" }],
        },
        {
          type: "ordered_list",
          attrs: { order: 1, type: "1", class: null },
          content: [
            {
              type: "list_item",
              content: [
                {
                  type: "container",
                  content: [
                    {
                      type: "paragraph",
                      attrs: {
                        startNewPage: false,
                        id: null,
                        class: null,
                        contenteditable: true,
                      },
                      content: [
                        {
                          type: "text",
                          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam facilisis fringilla erat, eu facilisis leo blandit et. Nam eu odio maximus odio mollis maximus.",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: "list_item",
              content: [
                {
                  type: "container",
                  content: [
                    {
                      type: "paragraph",
                      attrs: {
                        startNewPage: false,
                        id: null,
                        class: null,
                        contenteditable: true,
                      },
                      content: [
                        {
                          type: "text",
                          text: "Vivamus lobortis nulla tempor lorem laoreet, sit amet egestas ante volutpat. Donec dapibus, lorem vel congue blandit, libero velit imperdiet neque, et aliquam sem est ut quam.",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "paragraph",
          attrs: {
            startNewPage: false,
            id: "ac5b6423-2e74-4e9b-a432-6a23cc7f24be",
            class: null,
            contenteditable: true,
          },
        },
        {
          type: "paragraph",
          attrs: {
            startNewPage: false,
            id: "ac5b6423-2e74-4e9b-a432-6a41cc7f24be",
            class: null,
            contenteditable: true,
          },
          content: [
            {
              type: "text",
              text: "Executed and delivered by the Parties to this Agreement on the",
            },
            {
              type: "variable",
              attrs: {
                name: "Date of the agreement",
                description: "Date of the agreement",
                value: "2024-07-04",
                id: "29",
                answer: "2024-07-04",
              },
            },
          ],
        },
        {
          type: "paragraph",
          attrs: {
            startNewPage: false,
            id: "ecc21ca5-3f3a-40b1-adc7-40453a94d3ae",
            class: null,
            contenteditable: true,
          },
          content: [
            {
              type: "text",
              marks: [{ type: "strong" }],
              text: "\n            EXECUTED by",
            },
            { type: "text", text: "**signature**" },
          ],
        },
      ],
    },
  ],
};
