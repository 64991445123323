<script setup>
import ContactForm from "@/components/contact/ContactForm.vue";
import Page from "@/components/Page.vue";
import store from "@/store/store";

const user = computed(() => store.state.auth.user);
</script>

<template>
  <Page :title="$t('contact_form.contact_support')" :with-back-button="false">
    <div class="form-container">
      <ContactForm
        :name="!user.isTemporary ? user.name : ''"
        :email="!user.isTemporary ? user.email : ''"
        :with-title="false"
        class="contact-form"
      />
    </div>
  </Page>
</template>

<style scoped lang="scss">
.form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  background: transparent url("@/assets/backgrounds/auth.png") 30% 30%
  no-repeat padding-box;
  background-size: 40%;
  min-height: 620px;
  padding-bottom: 50px;
}

.contact-form {
  width: 500px;
  padding: 50px 50px 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;


  @media (max-width: 768px) {
    width: 100%;
  }
}

:deep(.dialog-content) {
  padding: 0 !important;
  margin: 0 !important;

  @media (max-width: 768px) {
    width: 100% !important;
    max-width: 100% !important;
  }
}
</style>
