<script setup lang="ts">
import axios from "axios";
import { useToast } from "vue-toastification";
import { Directory } from "@/types/documents/Directory";
import { Document } from "@/types/editor/Document";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import ButtonLink from "@/components/partials/ButtonLink.vue";
import DocumentPreview from "@/components/documents/list/DocumentPreview.vue";
import DeleteDocumentDialog from "@/components/dialogs/DeleteDocumentDialog.vue";

const emits = defineEmits(["refreshPage", "openDocument", "createDirectory", "uploadDocument"]);

const $t = useI18n().t;
const router = useRouter();

interface DocumentStatus {
  name: string;
  color: string;
  value: string;
}

const { document, documentStatuses, directories, showSignButton = true, showDeleteButton} = defineProps<{
  document: Document;
  documentStatuses: DocumentStatus[];
  directories: Directory[];
  showSignButton?: boolean;
  showDeleteButton?: boolean;
}>();

const documentStatusColor = computed(() => {
  const status = documentStatuses.find(
    (status: DocumentStatus) => status.name === document.status
  );
  return status ? status.color : "";
});

const filteredDirectoryList = computed(() => {
  if (document.directory == null) {
    return directories.filter((directory) => directory.id !== null);
  }

  return directories.filter(
    (directory) =>
      directory.id !==
      directories.find((directory) => directory.name === document.directory).id
  );
});

const selectedDocumentId = ref<string | null>(null);
const documentIdForDelete = ref<string | null>(null);

const handleShowDocument = (id: string) => {
  selectedDocumentId.value = id;
};

const handleSignDocument = async (id: string) => {
  if(router.currentRoute.value.name === "sign-documents") {
    emit('uploadDocument', id);
  }
  router.push(`/sign-documents`);
};

const handleDeleteDocument = (id: string) => {
  documentIdForDelete.value = id;
}

const moveToDirectory = async (id = null) => {
  try {
    await axios.put(`/api/documents/${document.id}/directory`, {
      directory_id: id,
    });
    useToast().success($t("notifications.document_moved"));
    emits("refreshPage");
  } catch (error) {
    useToast().error($t("errors.documents.move_error"));
  }
};
</script>

<template>
  <v-card>
    <template v-slot:prepend>
      <img src="/src/assets/icons/document.svg" alt="Documents list" />
    </template>
    <v-card-title class="pt-0">
      <span class="document-name">{{ document.name }}</span>
    </v-card-title>
    <v-card-text>
      <div class="d-flex justify-space-between mt-3">
        <div class="text-capitalize">
          <div class="document-info">
            {{ $t("documents.type") }}: {{ document.type }}
          </div>
          <div class="document-info">
            {{ $t("common.created_at") }}: {{ document.created_at }}
          </div>
          <div class="document-info">
            {{ $t("common.status") }}:
            <span :style="{ color: documentStatusColor }">{{
              document.status
            }}</span>
          </div>
        </div>
      </div>

      <div class="d-flex justify-space-between mt-3">
        <v-menu v-if="directories?.length">
          <template v-slot:activator="{ props }">
            <ButtonLink
              v-if="document.directory == null"
              variant="tonal"
              v-bind="props"
              class="document-links"
            >
              {{ $t("documents.move_to_directory") }}
            </ButtonLink>
            <ButtonLink
              v-else
              @click="moveToDirectory(null)"
              class="document-links"
            >
              {{ $t("documents.remove_from_directory") }}
            </ButtonLink>
          </template>
          <v-list>
            <v-list-item
              v-for="directory in filteredDirectoryList"
              :key="directory.id"
              @click="moveToDirectory(directory.id)"
            >
              <v-list-item-title>
                {{ directory.name }}
              </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="emits('createDirectory', document.id)">
              <v-list-item-title>
                {{ $t("documents.move_to_new_directory") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <ButtonLink
          @click="handleShowDocument(document.id)"
          class="document-links"
        >
          {{ $t("common.preview") }}
        </ButtonLink>
        <ButtonLink
          class="document-links"
          v-if="document.status === 'Ready for Review or Signature' && showSignButton"
          @click="handleSignDocument(document.id)"
        >
          {{ $t("common.sign") }}
        </ButtonLink>
        <ButtonLink
          v-if="showDeleteButton"
          class="document-links"
          @click="handleDeleteDocument(document.id)"
        >
          {{ $t("common.delete") }}
        </ButtonLink>
      </div>
    </v-card-text>
  </v-card>
  <template v-if="selectedDocumentId">
    <DocumentPreview
      :documentId="selectedDocumentId"
      @close="selectedDocumentId = null"
    />
  </template>
  <DeleteDocumentDialog
    :isDialogOpen="documentIdForDelete"
    :documentId="documentIdForDelete"
    :documentName="document.name"
    @closeDialog="documentIdForDelete = null"
    @refresh-documents="emits('refreshPage')"
  />
</template>

<style scoped lang="scss">
.document-name {
  font-size: 0.875rem;
  font-weight: 500;
}

.document-info,
.document-links {
  font-size: 0.688rem;
  font-weight: 500;
}
</style>
