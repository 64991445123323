<script setup>
import axios from "axios";
import { ref } from "vue";
import DocumentCardMobile from "@/views/app/mobile/DocumentCardMobile.vue";

const { items } = defineProps(["items"]);

const isExpanded = ref(false);

const expand = () => {
  isExpanded.value = !isExpanded.value;
};

const arrowRotation = computed(() => {
  return isExpanded.value ? 'rotate(180deg)' : 'rotate(0deg)';
});

const documents = ref([]);
const getDocuments = async () => {
  try {
    const response = await axios.get("/api/documents", {
      params: {
        per_page: items,
        sort: "-created_at",
      },
    });

    if (!documents.value.length) {
      documents.value = response.data.data;
    }
  } catch (error) {
    console.error(error);
  }
};

onMounted(() => {
  getDocuments();
});
</script>

<template>
  <div class="latest-documents-container">
    <div class="latest-documents-header" @click="expand">
      <h3> {{ $t('home.latest_documents_mobile') }} </h3>
      <img :style="{ transform: arrowRotation }"  src="/images/icons/arrow.svg" alt="expand-instruction" class="expand-arrow">
    </div>
    <div class="latest-documents" :class="{ showing: isExpanded, hidden: !isExpanded}">
      <div v-for="document in documents" v-bind:key="document.id">
        <DocumentCardMobile
          :document="document"
        />
      </div>
      <div v-if="documents.length === 0">
        <p> {{$t('errors.documents.not_found')}} </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.latest-documents-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .latest-documents-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;
    color: var(--eerie-black);
    font-size: 0.875rem;
    font-weight: 600;
  }

  .latest-documents {
    display: flex;
    flex-direction: column;
    gap: 20px;

    height: auto;
    transition: all 0.5s;
    overflow: hidden;
    &.showing {
      max-height: 1000px;
      margin-top: 10px;
    }
    &.hidden {
      max-height: 0px;
      margin-top: 0;
    }
  }
}

.expand-arrow {
  height: 5px;
  width: 10px;
  transition: 0.2s ease-in-out;
}
</style>
