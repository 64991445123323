<script setup>
import axios from "axios";
import { useI18n } from "vue-i18n";

const { t } = useI18n()
const emit = defineEmits(["closeDialog"])
const props = defineProps(["document", "isDialogOpen"])

const headers = ref([
  {
    title: t('common.email'),
    align: 'start',
    value: 'user_email',
  },
  {
    title: t('common.sent_at'),
    value: 'created_at'
  },
  {
    title: t('common.opened_at'),
    value: 'opened_at'
  },
])

const history = ref([]);


onMounted(async () => {
  const response = await axios.get(`/api/documents/${props.document.id}/share-history`);
  history.value = response.data.data;
});

</script>

<template>
  <v-dialog v-model="props.isDialogOpen" width="800">
    <v-card>
      <div class="close-container">
        <img @click="emit('closeDialog')" src="/images/icons/close.svg" alt="close dialog"/>
      </div>

      <v-card-title class="dialog-title">
        {{ $t('common.history_of_sharing') }} - {{ props.document.name }}
      </v-card-title>

      <v-card-text class="dialog-content">
        <v-data-table-virtual
          :fixed-header="true"
          :headers="headers"
          :items="history"
          item-value="name"
        ></v-data-table-virtual>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.dialog-title {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 23px 23px 0px;
}

@media (max-width: 768px) {
  :deep(.v-overlay__content) {
    min-width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
    margin-top: 75px !important;
    height: 100% !important;
    padding-bottom: 85px;
  }

  :deep(.v-card-text) {
    overflow: auto;
  }

  :deep(td), :deep(th) {
    text-wrap: nowrap;
  }

  :deep(.v-card-title) {
    white-space: normal;
  }
}

.close-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20px;

  img {
    cursor: pointer;
  }
}
</style>
