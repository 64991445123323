<script setup>
import { onMounted, ref } from "vue";
import axios from "axios";
import { useToast } from "vue-toastification";
import PlansModalBase from "@/components/users/profile/subscription_form/modal/plans/PlansModalBase.vue";
import SingleDocumentPlanCard from "@/components/users/profile/subscription_form/modal/plans/SingleDocumentPlanCard.vue";
import SubscriptionsPlanCard from "@/components/users/profile/subscription_form/modal/plans/SubscriptionsPlanCard.vue";
import store from "@/store/store";
import EmailDialog from "@/components/dialogs/EmailDialog.vue";

const planBenefits = {
  one_time: [
    "One-time document creation",
    "Customize and download instantly",
    "Ideal for infrequent users",
  ],
  recurring: ["Unlimited document creation", "Full access to all features"],
};

const { isDialogOpen, templateId, showCloseButton } = defineProps([
  "isDialogOpen",
  "templateId",
  "showCloseButton",
]);
const emit = defineEmits(["closeDialog"]);
const isPaymentDialogOpen = inject("isPaymentDialogOpen");

const isTemporaryUser = computed(() => store.state.auth.user.isTemporary);
const plans = ref([]);
const isOpen = ref(isDialogOpen);

const oneTimePlan = computed(() =>
  plans.value.find((plan) => plan.type === "one_time")
);
const recurringPlans = computed(() =>
  plans.value.filter((plan) => plan.type === "recurring")
);

const getPaymentRequiredInfo = async () => {
  const response = await axios.get(`/api/templates/${templateId}/payment-info`);
  plans.value = response.data;
};

const createSinglePayment = async (payment) => {
  await axios
    .post(`/api/user/payments/create/${payment.provider}`, {
      price_id: payment.price,
      product_id: templateId,
    })
    .then((res) => {
      emit("closeDialog");
      window.location.replace(res.data.data.checkout_url);
    })
    .catch((error) => {
      useToast().error(error.response.data.message);
    });
};

const handleCloseModal = () => {
  isOpen.value = false;
  emit("closeDialog");
};

watch(
  () => isDialogOpen,
  (value) => {
    isOpen.value = value;
  }
);

watch(
  () => isPaymentDialogOpen.value,
  (value) => {
    isOpen.value = value;
  }
);

onMounted(async () => {
  await getPaymentRequiredInfo();
});
</script>

<template>
  <EmailDialog
    v-if="isTemporaryUser"
    :isDialogOpen="isOpen"
    @closeDialog="handleCloseModal"
  />
  <PlansModalBase
    v-else
    class="SubscriptionOrSinglePaymentRequiredDialog"
    :open="isOpen"
    :show-close-button="true"
    @close="handleCloseModal"
  >
    <SubscriptionsPlanCard
      v-if="plans.length > 0"
      :plans="recurringPlans"
      :plans-benefits="planBenefits.recurring"
      @closeModal="handleCloseModal"
    />
    <SingleDocumentPlanCard
      v-if="plans.length > 0"
      :plan="oneTimePlan"
      :plans-benefits="planBenefits.one_time"
      @closeModal="handleCloseModal"
      @create-single-payment="createSinglePayment"
    />
  </PlansModalBase>
</template>
<style>
.SubscriptionOrSinglePaymentRequiredDialog .background-container {
  background: transparent url("@/assets/backgrounds/plan-background.png") 1.5% 0
    no-repeat padding-box;
}
.SubscriptionOrSinglePaymentRequiredDialog .v-card {
  margin-bottom: 50px !important;

  @media (max-width: 768px) {
    margin-bottom: 15px !important;
  }
}
</style>
