<script setup>
import Creator from "@/components/documents/creator/Creator.vue";
import EmailDialog from "@/components/dialogs/EmailDialog.vue";
import { useStore } from "vuex";
const store = useStore();

const user = computed(() => store.state.auth.user);
const isEmailDialogOpen = ref(false);
const isTemporaryUser = computed(() => user.value.isTemporary);
const showEmailDialogEveryMinutes = 2;

const showEmailDialog = () => {
  if (!isTemporaryUser.value || isEmailDialogOpen.value) {
    return;
  }

  setTimeout(() => {
    isEmailDialogOpen.value = true;
  }, showEmailDialogEveryMinutes * 60 * 1000);
};

const handleCloseEmailDialog = () => {
  isEmailDialogOpen.value = false;
};

watch(isEmailDialogOpen, () => {
  showEmailDialog();
});

onMounted(() => {
  showEmailDialog();
});
</script>

<template>
  <EmailDialog
    :user="user"
    :is-dialog-open="isEmailDialogOpen"
    @closeDialog="handleCloseEmailDialog"
  />
  <Creator />
</template>

<style scoped lang="scss"></style>
