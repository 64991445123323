<script setup>
import axios from "axios";
import {useI18n} from "vue-i18n";
import {useToast} from "vue-toastification";

const {plans, plansBenefits} = defineProps(["plans", "plansBenefits", "showCloseButton"]);
const emit = defineEmits(["closeModal"]);

const {t} = useI18n();

const createSubscription = async (plan, provider) => {
  await axios
    .post("/api/user/subscription/create", {
      plan: plan,
      provider: provider,
    })
    .then((res) => {
      emit("closeModal");
      window.location.replace(res.data.data.checkout_url);
    })
    .catch((error) => {
      useToast().error(error.response.data.message);
    });
};

const plan = computed(() => {
  return plans.reduce((minPlan, currentPlan) => {
    return currentPlan.unit_price < minPlan.unit_price ? currentPlan : minPlan;
  }, plans[0]);
});

const modifiedPlans = computed(() => {
  return plans.map(singlePlan => {
    return {
      ...singlePlan,
      name: singlePlan.name.replace(/subscription$/i, "").trim()
    };
  });
});

</script>
<template>
  <VCard
    class="default_plan"
    style="margin-bottom: 110px"
  >
    <VCardItem>
      <div class="plan-container">
        <span class="plan-title"> {{ t("billing.subscription") }} </span>
        <span class="plan-price-container">
          <span class="plan-price-subtitle">
            {{ t("billing.starting_from") }}
          </span>
        </span>
        <span class="plan-price-container">
          <span class="plan-price">
            {{ plan.unit_price }} {{ plan.currency_symbol }}
          </span>
          <span class="plan-price-suffix">
            / {{ t("billing.per_month") }}
          </span>
        </span>

        <v-menu>
          <template v-slot:activator="{ props }">
            <VBtn
              class="plan-button"
              variant="flat"
              v-bind="props"
            >
              {{ t("common.upgrade") }}
            </VBtn>
          </template>

          <v-list>
            <v-list-item
              :title="$t('billing.traditional_payments')"
              key="stripe"
              @click="() => createSubscription(plan.id, 'stripe')"
            />
            <v-list-item
              :title="$t('billing.crypto_payments')"
              key="vaiot"
              @click="() => createSubscription(plan.id, 'vaiot')"
            />
          </v-list>
        </v-menu>

        <div class="available-plans">
          <span class="available-plan-title"> {{ t("billing.available_plans") }} </span>
          <div class="plans-list">
            <span class="single-plan" v-for="singlePlan in modifiedPlans">
              <img src="/src/assets/icons/tick.png"/>
              <span> {{ singlePlan.name }} </span>
            </span>
          </div>
        </div>
        <div class="features-list">
          <span class="feature" v-for="feature in plansBenefits">
            <img src="/src/assets/icons/list-arrow.svg"/>
            <span> {{ feature }} </span>
          </span>
        </div>
      </div>
    </VCardItem>
    <img
      width="150"
      height="120"
      style="position: absolute; right: 0; bottom: 0"
      src="/src/assets/images/plan-recommended.svg"
      alt="Default Plan"
    />
  </VCard>
</template>
<style scoped lang="scss">
.plan , .default_plan {
  width: 320px;
  min-height: 365px;
  max-height: 365px;

  @media (max-width: 768px) {
    width: 100% !important;
    padding: 30px;
    padding-top: 0 !important;
    min-height: 310px !important;
    max-height: 310px !important;
    box-shadow: none !important;
  }
}

.plan-container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  .available-plans {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .available-plan-title {
      font-size: 0.688rem;
      color: var(--eerie-black);
    }

    .plans-list {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 100%;
      margin-top: 10px;

      .single-plan {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 0.688rem;
        color: var(--dark-grey);

        img {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .plan-marker-icon {
    position: absolute;
    top: 0;
    left: 20px;
    width: 30px;
    height: 30px;
    z-index: 2;
  }

  .plan-title {
    width: 100%;
    display: flex;
    font-size: 1.25rem;
    color: var(--eerie-black);
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 20px;
    padding-left: 25px;

    @media (max-width: 768px) {
      margin-bottom: 0;
    }
  }

  .plan-price-container {
    display: flex;
    align-items: flex-start;
    gap: 6px;
  }

  .plan-price-subtitle {
    font-size: 0.688rem;
    color: var(--dark-grey);
    margin-left: 25px;
  }

  .plan-price {
    font-size: 1.5rem;
    color: var(--eerie-black);
    font-weight: bold;
    text-transform: uppercase;
    margin-left: 25px;
  }

  .plan-price-subtitle-suffix {
    font-size: 0.5rem;
    color: var(--cambridge-blue);
  }

  .plan-price-suffix {
    font-size: 0.688rem;
    color: var(--dark-grey);
    margin-top: 5px;
  }

  .plan-button {
    margin-top: 20px;
    background-color: var(--blue-bonnet);
    color: white;
    font-size: 1.125rem;
    font-weight: bold;
    padding: 5px;
    margin-right: 25px;
    margin-left: 25px;
  }
}

.features-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 20px auto;

  @media (max-width: 768px) {
    margin: 20px 55px;
  }
}

.feature {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.688rem;
  color: var(--dark-grey);
}

.default_plan {
  min-height: 408px;
  position: relative;
  box-shadow: 0px 3px 6px #00000029;
}

.v-card-item {
  padding: 0;
}
</style>
