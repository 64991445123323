<script setup>

</script>

<template>
  <button class="link">
    <slot />
  </button>
</template>

<style scoped lang="scss">
  .link {
    height: auto;
    width: auto;
    text-decoration: underline;
    font-weight: 500;
  }
</style>
