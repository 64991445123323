<script setup lang="ts">

import CategoryItem from "@/components/documents/search_engine/categories_list/CategoryItem.vue";

const { categories, activeCategory } = defineProps([
  "categories",
  "activeCategory",
]);
const emit = defineEmits(["openCategory", "clickDocument"]);

const handleClickCategory = async (categoryId: string) => {
  emit("openCategory", categoryId);
};
</script>
<template>
  <ul class="categories" v-if="categories.length">
    <template v-for="category in categories">
      <CategoryItem
        v-if="category.children && category.children.length"
        :category="category"
        :documents="category.children"
        :isOpen="activeCategory === category.id"
        @clickCategory="handleClickCategory"
      />
    </template>
  </ul>
</template>
<style scoped lang="scss">
.categories {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
</style>
